import React, { ReactNode } from 'react'
import { Row, Col } from 'antd'

import { AtomsCard } from '@atoms'

// import Styles from './MainContent.module.scss'

interface PropTypes {
  children?: ReactNode
  anotherCololumn?: ReactNode
}

const MoleculesMainContent = (props: PropTypes) => {
  const { children, anotherCololumn } = props
  return (
    <Row gutter={[24, 24]}>
      {anotherCololumn}
      <Col span={24}>
        <AtomsCard>
          {children}
        </AtomsCard>
      </Col>
    </Row>
  )
}

export default MoleculesMainContent

import { Form, Input, Button } from 'antd'
import { useContext, useState } from 'react'
import AppContext, { AppActionTypes } from '@/context/App'
import { addNote } from '@/context/Notes/action'
import { triggerError } from '@/context/App/action'
import { AdminNoteEntityType } from '@/types/entity'

interface PropTypes {
  onSubmit: () => void
  identifier: string
  type: AdminNoteEntityType
}

const NoteCommentForm = ({ onSubmit = () => null, type, identifier }: PropTypes) => {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)

  const { dispatch: dipatchApp }: any = useContext(AppContext)

  const onFinish = ({ reason }: { reason: string }) => {
    addNote({
      data: { isComment: true, pageType: type, identifier, reason },
      progress: (p = false) => setLoading(p),
      reject: (error) => dipatchApp({ type: AppActionTypes.SET_MESSAGE, payload: triggerError(true, error) }),
      resolve: () => {
        dipatchApp({
          type: AppActionTypes.SET_MESSAGE,
          payload: {
            show: true,
            type: 'success',
            message: 'Your comment has been successfully published!'
          }
        })

        form.resetFields()
        onSubmit()
      }
    })
  }

  return (
    <Form
      form={form}
      id="admin-note-comment"
      layout="vertical"
      name="admin-note-comment"
      initialValues={{
        remember: false,
        requiredMarkValue: false
      }}
      requiredMark={false}
      onFinish={onFinish}
    >
      <Form.Item
        name="reason"
        rules={[
          { required: true, message: 'Please input your Comment!' }
        ]}
      >
        <Input.TextArea placeholder="Add a comment" />
      </Form.Item>

      <Form.Item shouldUpdate>
        {() =>
            <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            Send
          </Button>
        }
      </Form.Item>
    </Form>
  )
}

export default NoteCommentForm

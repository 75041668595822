import HTTP from './http'

const GET_API = (path: string, data?: any, headers: any = {}) => {
  const promise = new Promise((resolve, reject) => {
    HTTP.get(path, data, headers)
      .then(result => {
        resolve(result.data)
      })
      .catch(err => {
        reject(err)
      })
  })
  return promise
}

const POST_API = (path: string, data?: any, headers: any = {}) => {
  const promise = new Promise((resolve, reject) => {
    HTTP.post(path, data, headers)
      .then(result => {
        resolve(result.data)
      })
      .catch(err => {
        reject(err)
      })
  })
  return promise
}

const PATCH_API = (path: string, data?: any, headers: any = {}) => {
  const promise = new Promise((resolve, reject) => {
    HTTP.patch(path, data, headers)
      .then(result => {
        resolve(result.data)
      })
      .catch(err => {
        reject(err)
      })
  })
  return promise
}

const PUT_API = (path: string, data?: any, headers: any = {}) => {
  const promise = new Promise((resolve, reject) => {
    HTTP.put(path, data, headers)
      .then(result => {
        resolve(result.data)
      })
      .catch(err => {
        reject(err)
      })
  })
  return promise
}

const DELETE_API = (path: string, query?: any, data?: any, headers: any = {}) => {
  const promise = new Promise((resolve, reject) => {
    HTTP.delete(path, data, headers, query)
      .then(result => {
        resolve(result.data)
      })
      .catch(err => {
        reject(err)
      })
  })
  return promise
}

const methods = {
  GET_API,
  POST_API,
  PATCH_API,
  PUT_API,
  DELETE_API
}

export default methods

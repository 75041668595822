import { GetAdminsRequest, InviteAdminRequest, UpdateAdminRequest } from '@/types/contracts/admin'

import methods from '../methods'

const API_URL = '/api/admin/users'

const getAdmins = (params: GetAdminsRequest) => methods.GET_API(`${API_URL}/admins?${new URLSearchParams(params as any).toString()}`)
const getAdmin = (serial: string) => methods.GET_API(`${API_URL}/admin/${serial}`)
const updateAdmin = (params: UpdateAdminRequest) => methods.PUT_API(`${API_URL}/admin/${params.userSerial}`, params)
const inviteAdmin = (params: InviteAdminRequest) => methods.POST_API(`${API_URL}/admins/invite`, params)

export default {
  getAdmins,
  getAdmin,
  updateAdmin,
  inviteAdmin
}

import { RouteType } from '@/types/routes'
import {
  CreditCardOutlined,
  LayoutOutlined,
  PicCenterOutlined,
  ReadOutlined,
  UserOutlined
} from '@ant-design/icons'
import { RolePermissions } from '@/utils/role'

export const routes: RouteType[] = [
  {
    type: 'single',
    name: 'Dashboard',
    key: 'dashboard',
    route: '/',
    icon: <LayoutOutlined />
  },
  {
    type: 'collapse',
    name: 'Users',
    key: 'users',
    icon: <UserOutlined />,
    collapse: [
      {
        permission: RolePermissions.rolemgmt_view.id,
        type: 'single',
        name: 'Role Management',
        key: 'roles',
        route: '/users/roles'
      },
      {
        permission: RolePermissions.adminmgmt_view.id,
        type: 'single',
        name: 'Admin',
        key: 'admin',
        route: '/users/admin'
      },
      {
        permission: RolePermissions.usermgmt_view.id,
        type: 'single',
        name: 'User',
        key: 'user',
        route: '/users/user'
      },
      {
        permission: RolePermissions.facilitatormgmt_view.id,
        type: 'single',
        name: 'Facilitator',
        key: 'facilitator',
        route: '/users/facilitator'
      },
      {
        permission: RolePermissions.rolemgmt_view.id,
        type: 'single',
        name: 'Permissions',
        key: 'permissions',
        route: '/users/permissions'
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Course',
    key: 'course',
    icon: <ReadOutlined />,
    permission: RolePermissions.course_view.id,
    collapse: [
      {
        type: 'single',
        name: 'Courses List',
        key: 'course-list',
        route: '/course/course-list'
      },
      {
        type: 'single',
        name: 'Quiz',
        key: 'quiz',
        route: '/course/quiz'
      },
      {
        type: 'single',
        name: 'Task',
        key: 'task',
        route: '/course/task'
      },
      {
        type: 'single',
        name: 'Featured Courses',
        key: 'featured-course',
        route: '/course/featured-course'
      },
      {
        type: 'single',
        name: 'Certificate',
        key: 'certificate',
        route: 'course/certificate'
      },
      {
        type: 'single',
        name: 'Rating & Review',
        key: 'rating-review',
        route: '/course/rating-review'
      },
      {
        type: 'single',
        name: 'Vouchers',
        key: 'vouchers'
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Transaction',
    key: 'transaction',
    icon: <CreditCardOutlined />,
    permission: RolePermissions.course_view.id,
    collapse: [
      {
        type: 'single',
        name: 'Transaction List',
        key: 'transaction-list',
        route: '/transaction/transaction-list'
      }
    ]
  },
  {
    type: 'collapse',
    name: 'Site Menu',
    key: 'site-menu',
    icon: <PicCenterOutlined />,
    collapse: [
      {
        permission: RolePermissions.banner_view.id,
        type: 'single',
        name: 'Banner',
        key: 'banner',
        route: 'site-menu/banner'
      }
    ]
  }
]

/* eslint-disable comma-dangle */

export type AllowedRoles = keyof typeof Roles
export const Roles = {
  superadmin: {
    id: 'superadmin',
    display: 'Superadmin'
  },
  product: {
    id: 'product',
    display: 'Product'
  },
  administrator: {
    id: 'administrator',
    display: 'Administrator'
  },
  marketing: {
    id: 'marketing',
    display: 'Marketing'
  },
  data: {
    id: 'data',
    display: 'Data'
  }
}

const RolesToDisplays = Object.keys(Roles).reduce(
  (obj: Record<string, string>, key: string) => ({
    ...obj,
    [key]: Roles[key as AllowedRoles].display
  }),
  {}
)
export function getRoleDisplayFromID(id: string) {
  return RolesToDisplays[id]
}

const DisplaysToRoles = Object.keys(Roles).reduce(
  (obj: Record<string, string>, key: string) => ({
    ...obj,
    [Roles[key as AllowedRoles].display]: key
  }),
  {}
)
export function getRoleIDFromDisplay(display: string) {
  return DisplaysToRoles[display]
}

export type AllowedRolePermissions = keyof typeof RolePermissions
export const RolePermissions = {
  // banner
  banner_view: {
    id: 'banner.view',
    display: 'Banner - View'
  },
  banner_update: {
    id: 'banner.update',
    display: 'Banner - Create/Update'
  },
  banner_delete: {
    id: 'banner.delete',
    display: 'Banner - Delete'
  },

  // course
  course_view: {
    id: 'course.view',
    display: 'Course - View'
  },
  course_update: {
    id: 'course.update',
    display: 'Course - Create/Update'
  },
  course_delete: {
    id: 'course.delete',
    display: 'Course - Delete'
  },

  // role management
  rolemgmt_view: {
    id: 'rolemgmt.view',
    display: 'Role Management - View'
  },
  rolemgmt_update: {
    id: 'rolemgmt.update',
    display: 'Role Management - Update'
  },

  // admin management
  adminmgmt_view: {
    id: 'adminmgmt.view',
    display: 'Admin Management - View'
  },
  adminmgmt_update: {
    id: 'adminmgmt.invite',
    display: 'Admin Management - Update'
  },
  adminmgmt_invite: {
    id: 'adminmgmt.invite',
    display: 'Admin Management - Invite'
  },

  // user management
  usermgmt_view: {
    id: 'usermgmt.view',
    display: 'User Management - View'
  },
  usermgmt_update: {
    id: 'usermgmt.update',
    display: 'User Management - Update'
  },

  // facilitator management
  facilitatormgmt_view: {
    id: 'facilitatormgmt.view',
    display: 'Facilitator Management - View'
  },
  facilitatormgmt_update: {
    id: 'facilitatormgmt.update',
    display: 'Facilitator Management - Update'
  }
}

import { Button, Form, Input } from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import FormItem from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import { createFacilitator } from '../../../modules/users/Facilitator/context/action'
import { Facilitator } from '../../../types/entity'
import UploadImagePreview from '../UploadImagePreview'

import styles from './FacilitatorCreationForm.module.scss'

interface Props {
  onCancel: () => void,
  onSaved: (facilitator: Facilitator) => void,
  userData?: any;
}

const FacilitatorCreationForm = ({ onCancel, userData, onSaved }: Props) => {
  const [form] = useForm()
  const onSubmit = (data: any) => {
    createFacilitator({
      data,
      resolve: (facilitator) => {
        onSaved(facilitator)
      }
    })
  }

  const requiredRules: Rule[] = [{ required: true, message: 'This field cannot be empty' }]

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical" className="kg">
      <FormItem rules={requiredRules} label="Name" required>
        <Input placeholder="Insert facilitator name" value={userData.fullName} disabled />
      </FormItem>
      <FormItem name="userSerial" hidden initialValue={userData.serial} />
      <FormItem rules={requiredRules} label="Position" name="role" required>
        <Input placeholder="Insert facilitator position" />
      </FormItem>
      <FormItem rules={requiredRules} label="Short Introduction" name="description" required>
        <TextArea placeholder="Insert facilitator intro" />
      </FormItem>
      <FormItem rules={requiredRules} label="Upload Photo" name="imageURL" required>
        <UploadImagePreview />
      </FormItem>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const errors = !!form.getFieldsError().filter(({ errors }: any) => errors.length).length

          return (
            <div className={styles['buttons-container']}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={errors}
                // loading={updating}
              >
                Save
              </Button>
            </div>
          )
        }}
      </Form.Item>
    </Form>
  )
}

export default FacilitatorCreationForm

/* eslint-disable no-await-in-loop */
import { UploadOutlined, PlaySquareOutlined, DeleteOutlined } from '@ant-design/icons'
import { Upload, Button, Space, message, UploadProps, Modal, Progress, Spin } from 'antd'
import { RcFile } from 'antd/lib/upload'
import axios, { AxiosError } from 'axios'
import { fetchVideoUploadToken, updateUploadVideoContentStatus } from '@/modules/course/CourseList/context/action'
import { useEffect, useState } from 'react'

import styles from './UploadVideo.module.scss'
import { VideoUploadResource } from '@/types/contracts/video-upload'
import helper from '@/utils/helper/helper'

interface Props {
  onChange?: (attachmentSerial?: string) => void,
  value?: string,
  maxSize?: number // in mb,
}

const chunkSize = 5 * 1024 * 1024 // 5MB

const UploadVideo = ({
  onChange = (e) => e,
  value,
  maxSize = 3000
}: Props) => {
  const [video, setVideo] = useState<string | undefined>(value)
  const [uploadVideoLoading, setUploadVideoLoading] = useState<boolean>(false)
  const [uploadStage, setUploadStage] = useState<string>('Preparing...')
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (!uploadVideoLoading) {
      setUploadProgress(0)
    }
  }, [uploadVideoLoading])

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])

  // TODO: refactor to impl useAPICall
  const uploadVideo = (duration: number, callback: any) => {
    setUploadVideoLoading(true)
    setUploadStage('Reserving storage...')
    fetchVideoUploadToken({
      data: duration,
      resolve: callback,
      reject: () => {
        // TODO: record the error
        message.error('Failed to prepare the uploading process. Please contact the administrator.')
        setVideo(undefined)
        setUploadVideoLoading(false)
      }
    })
  }

  const finishVideoUploading = (attachmentSerial: string) => {
    setUploadStage('Finalizing...')
    updateUploadVideoContentStatus({
      data: { attachmentSerial, isUploadSuccess: true },
      reject(error: any) {
        setUploadVideoLoading(false)
        message.error(`Something went wrong when uploading video, please contact the administrator. ${error}`)
      },
      resolve() {
        onChange(attachmentSerial)
        setUploadVideoLoading(false)
        message.success('File is uploaded successfully!')
      }
    })
  }

  const removeVideo = () => {
    setVideo(undefined)
    onChange(undefined)
  }

  const checkFileSize = (size: number) => {
    let isMaxSize = null
    if (maxSize < 1000) {
      isMaxSize = size / 1024 / 1024 <= maxSize
    } else {
      isMaxSize = size / 1024 / 1024 / 1024 <= maxSize
    }
    return isMaxSize
  }


  const propsVideo: UploadProps = {
    name: 'file',
    accept: 'video/mp4',
    beforeUpload(file: RcFile) {
      const isMp4 = file.type === 'video/mp4'
      if (!isMp4) {
        message.error('You can only upload MP4 file!')
        return false
      }
      let isMaxSize = null
      if (maxSize < 1000) {
        isMaxSize = file.size / 1024 / 1024 <= maxSize
      } else {
        isMaxSize = file.size / 1024 / 1024 / 1024 <= maxSize
      }
      if (!isMaxSize) {
        message.error(`File must be smaller than ${maxSize > 1000 ? maxSize / 1000 + 'GB' : maxSize + 'MB'}!`)
        return false
      }
      return false
    },
    async onChange({ file }) {
      const f = file as unknown as File
      if (checkFileSize(f.size)) {
        setVideo(f.name)

        setUploadStage('Calculating video duration...')
        const duration = await helper.getVideoDuration(f)
        uploadVideo(duration, async ({ uploadId, uploadToken, attachmentSerial }: VideoUploadResource) => {
          setUploadStage('Splitting file into chunks...')

          const fileSize = f.size || 0
          let offset = 0

          // to avoid using existing auth interceptor
          const req = axios.create()

          const batches = Math.ceil(fileSize / chunkSize)

          let parts
          try {
            const { data } = await req.get(
              `https://api.jwplayer.com/v2/uploads/${uploadId}/parts?page_length=${batches}`,
              { headers: { Authorization: `Bearer ${uploadToken}` } }
            )
            parts = data.parts
          } catch (error) {
            message.error(`Something went wrong, please contact the administrator. ${error}`)
            return
          }

          setUploadStage('Uploading...')
          let idx = 0
          while (offset < fileSize) {
            const chunk = f.slice(offset, offset + chunkSize)
            const { upload_link: uploadUrl } = parts[idx]
            try {
              await req.put(uploadUrl, chunk, { headers: { 'Content-Type': '' } })
              idx++
              offset += chunkSize
              setUploadProgress(offset / fileSize * 100)
            } catch (error) {
              const e = error as unknown as AxiosError
              if (e.response?.statusText === 'Slow Down') {
                await new Promise(resolve => setTimeout(resolve, 100))
                continue
              }
              message.error(`Something went wrong when uploading file, please contact the administrator. ${error}`)
              setUploadVideoLoading(false)
              break
            }
          }

          if (offset >= fileSize) {
            finishVideoUploading(attachmentSerial)
          }
        })
      } else {
        setFileList([])
      }

    },
    disabled: uploadVideoLoading
  }

  return (
    <>
      <div className={`${styles['placeholder-upload']} mb-2`}>
        Max file size. {maxSize > 1000 ? `${maxSize / 1000}GB` : `${maxSize}MB`} in .mp4 formats
      </div>
      { !video && <Upload className={styles['content-uploader']} {...propsVideo} fileList={fileList}>
        <Button size="large" style={{ borderColor: '#2A9D8C' }} icon={<UploadOutlined />}>Upload File</Button>
      </Upload>}

      { video && <ul className={styles['files-upload']}>
        <li>
          <Space>
            <PlaySquareOutlined />
            <div className={styles['files-upload__name']}>{video ?? '-'}</div>
          </Space>
          <Button style={{ color: '#D60B00' }} type="link" icon={<DeleteOutlined />} onClick={removeVideo} />
        </li>
      </ul>}

      <Modal className="kg" visible={uploadVideoLoading} footer={false} closable={false}>
        <span className="mr-2">{uploadStage}</span>{uploadProgress === 0 && <Spin />}
        <Progress percent={Math.round(uploadProgress)} strokeColor="primary" />
      </Modal>
    </>
  )
}

export default UploadVideo

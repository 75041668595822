import React from 'react'
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import Icon from '@ant-design/icons'


const IconDownloadFile = () => {
  const DownloadFileSvg = () =>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.278 0.229171L17.0568 0H3.61108V26H23.1111V6.38634L22.9015 6.17091C22.0068 5.24903 20.7075 3.86546 19.5609 2.64457C18.6403 1.664 17.7959 0.765143 17.278 0.229171ZM20.616 5.94211H17.485V2.61597C17.7925 2.94209 18.1213 3.29271 18.4626 3.65597C19.1656 4.40514 19.9246 5.213 20.616 5.94211ZM5.11097 24.5143V1.48571H15.9851V7.42783H21.6112V24.5143H5.11097Z" fill="#2A9D8C" />
      <path d="M17.3334 13.2788L16.2997 12.2239L14.0921 14.477V6.5H12.6301V14.477L10.4225 12.2239L9.38892 13.2788L13.3611 17.3333L17.3334 13.2788Z" fill="#2A9D8C" />
      <path d="M17.965 18.7468H8.75708V15.8887H7.22217V20.222H19.4999V15.8887H17.965V18.7468Z" fill="#2A9D8C" />
    </svg>


  const DownloadFileIcon = (props: Partial<CustomIconComponentProps>) =>
    <Icon component={DownloadFileSvg} {...props} />

  return (
    <DownloadFileIcon />
  )
}

export default IconDownloadFile

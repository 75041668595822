import methods from '../methods'

const API_URL = '/api/admin'

const getTransactionList = (params?: string) => methods.GET_API(`${API_URL}/transactions?${params}`)
const getTransactionDetail = (serial?: string) => methods.GET_API(`${API_URL}/transactions/${serial}`)

export default {
  getTransactionList,
  getTransactionDetail
}

import { Tag } from 'antd'

interface Props {
  name: string
}

const StatusChip = ({ name }: Props) => {
  let color: string
  switch (true) {
    case ['draft'].includes(name.toLowerCase()):
      color = 'grey'
      break
    case ['active', 'succeeded'].includes(name.toLowerCase()):
      color = 'green'
      break
    case ['inactive', 'failed'].includes(name.toLowerCase()):
      color = 'red'
      break
    case ['archived', 'awaiting_payment'].includes(name.toLowerCase()):
      color = 'yellow'
      break
    default:
      color = 'black'
      break
  }
  return <Tag color={color}>{name}</Tag>
}

export default StatusChip

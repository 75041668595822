import auth from './auth'
import notes from './notes'
import banner from './banner'
import upload from './upload'
import occupation from './occupation'
import studyReason from './studyReason'
import topic from './topic'
import fields from './fields'
import users from './users'
import course from './course'
import category from './category'
import content from './content'
import tags from './tags'
import facilitator from './facilitator'
import rbac from './rbac'
import rating from './rating'
import admin from './admin'
import voucher from './voucher'
import transaction from './transaction'

export default {
  auth,
  notes,
  banner,
  upload,
  occupation,
  studyReason,
  topic,
  fields,
  users,
  course,
  category,
  content,
  tags,
  facilitator,
  rbac,
  rating,
  admin,
  voucher,
  transaction
}

import { LoginSSORequest, ValidationInvitationRequest } from '@/types/contracts/auth'
import methods from '../methods'

export interface LoginProps {
  email: string;
  password: string;
}

const API_URL = '/api/admin'

const login = (data: LoginProps) => methods.POST_API(`${API_URL}/login`, data)
const loginSSO = (data: LoginSSORequest) => methods.POST_API(`${API_URL}/sso`, data)
const getPermissions = (headers?: any) => methods.GET_API(`${API_URL}/rbac/permissions`, {}, headers)
const validateInvitation = (data: ValidationInvitationRequest) => methods.POST_API('/api/users/admins/invites/validate-admin', data)

export default {
  login,
  getPermissions,
  loginSSO,
  validateInvitation
}

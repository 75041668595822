import { DataPagesType, Action } from '@/types/context'
import { RatingTypes } from '@/types/entity'

export enum ActionTypes {
  SET_RATINGS = 'SET_RATINGS',
  SET_RATINGS_LOADING = 'SET_RATINGS_LOADING'
}

export interface State {
  ratings: DataPagesType<RatingTypes[]>
}

const reducer = (state: State, action: Action<ActionTypes>): State => {
  switch (action.type) {
    case ActionTypes.SET_RATINGS:
      return {
        ...state,
        ratings: {
          data: action?.payload?.data ?? [],
          meta: action?.payload?.meta ?? null,
          isLoading: false
        }
      }
    default:
      return state
  }
}

export default reducer

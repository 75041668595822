import { CreateVoucherRequest, GetVouchersRequest, UpdateVoucherRequest } from '@/types/contracts/voucher'
import methods from '../methods'

const API_URL = '/api/admin'

const createVoucher = (req: CreateVoucherRequest) => methods.POST_API(`${API_URL}/vouchers`, req)
const updateVoucher = (req: UpdateVoucherRequest) => methods.PUT_API(`${API_URL}/vouchers`, req)
const getVouchers = (req: GetVouchersRequest) => methods.GET_API(`${API_URL}/vouchers`, req)
const getVoucherClaims = (serial: string) => methods.GET_API(`${API_URL}/vouchers/${serial}/claims`)
const getVoucherDetails = (serial: string) => methods.GET_API(`${API_URL}/vouchers/${serial}`)
const deleteVoucher = (serial: string) => methods.DELETE_API(`${API_URL}/vouchers/${serial}`)

export default {
  createVoucher,
  updateVoucher,
  getVouchers,
  getVoucherClaims,
  getVoucherDetails,
  deleteVoucher
}

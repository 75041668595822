import { Upload, UploadProps } from 'antd'

interface PropTypes extends UploadProps {
  children?: React.ReactNode
  onFileChange?: (f: File) => void
}

const MinimalUploadWrapper = ({ children, onFileChange = (f) => f, ...rest }: PropTypes) =>
  <Upload
    customRequest={({ file: f }) => onFileChange(f as File)}
    showUploadList={false}
    {...rest}
  >
    {children}
  </Upload>

export default MinimalUploadWrapper

import React from 'react'
import { Modal } from 'antd'

interface ModalType {
  onCancel?: any
  actionModal?: any
  title?: string
  description?: string
  width?: string | number
}

const AtomsModalConfirm = ({ onCancel, actionModal, title, description, width = 400 }: ModalType) => {
  return (
    <Modal className="kg modal-confirm" centered={true} visible={true} footer={null} closable={false} width={width} onCancel={onCancel}>
      { title && <div className="font-bold text-xl">{title}</div>}
      { description && <div className="text-base mt-4 font-normal" style={{ color: 'rgba(49, 53, 59, 0.68)' }}>{description}</div>}
      { actionModal && actionModal }
    </Modal>
  )
}

export default AtomsModalConfirm

import { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { QuizActionTypes, QuizState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: QuizState = {
  quizDetail: {
    data: undefined,
    isLoading: false
  },
  quizParticipant: {
    data: undefined,
    isLoading: false
  }
}

const QuizContext = createContext<ContextType<QuizState, Action<QuizActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const QuizProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <QuizContext.Provider value={{ ...state, dispatch }}>
    {children}
  </QuizContext.Provider>

}

export default QuizContext
export { QuizActionTypes }

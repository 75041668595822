import Cookies from 'js-cookie'
import { result } from 'lodash'

const cookieServices = (() => {

  function _setSpecificCookie(name: string, data: any) {
    Cookies.set(name, data, { expires: 1 })
  }

  function _getSpecificCookie(name: string) {
    return Cookies.get(name)
  }

  function _setCookie(data: any) {
    Cookies.set('token', data?.token, { expires: 1 })
    Cookies.set('email', data?.email, { expires: 1 })
    Cookies.set('role', data?.role, { expires: 1 })
  }

  function _setToken(data: any) {
    Cookies.set('token', result(data, 'token'), { expires: 1 })
  }

  function _setSession(data: any) {
    Cookies.set('session', JSON.stringify(data), { expires: 1 })
  }

  function _getToken() {
    return Cookies.get('token')
  }

  function _getSession() {
    let user: any = {
      email: Cookies.get('email'),
      role: Cookies.get('role')
    }
    if (!Cookies.get('email') && !Cookies.get('role')) {
      user = null
    }
    return user
  }

  function _clearCookie() {
    Cookies.remove('token')
    Cookies.remove('email')
    Cookies.remove('role')
  }

  return {
    setSpecificCookie: _setSpecificCookie,
    getSpecificCookie: _getSpecificCookie,
    setCookie : _setCookie,
    setToken: _setToken,
    setSession: _setSession,
    getToken : _getToken,
    getSession: _getSession,
    clearCookie : _clearCookie
  }
})()

export default cookieServices

import React, { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { State, ActionTypes } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: State = {
  learners: {
    data: [],
    meta: {
      page: 0,
      pageSize: 0,
      total: 0,
      totalPage: 0
    },
    isLoading: false
  },
  learner: {
    data: {
      dob: '',
      email: '',
      fullName: '',
      lastLogin: '',
      learnerStatus: '',
      occupationSerial: '',
      onboardingState: '',
      phoneNumber: '',
      professionSerial: '',
      role: '',
      serial: '',
      studyReasonSerial: '',
      topicInterests: []
    },
    isLoading: false
  },
  occupations: {
    data: [],
    isLoading: false
  },
  studyReasons: {
    data: [],
    isLoading: false
  },
  fields: {
    data: [],
    isLoading: false
  },
  topics: {
    data: [],
    isLoading: false
  }
}


const UserContext = createContext<ContextType<State, Action<ActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const UserProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <UserContext.Provider value={{ ...state, dispatch }}>
    {children}
  </UserContext.Provider>

}

export default UserContext
export { ActionTypes }

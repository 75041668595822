import API_URL from '@/services/api'
import { ActionParamsGetList } from '@/types/context'
import { Content, CourseType, QueryCourseProps, Tag, UpsertCourseProps } from '@/types/entity'
import { APICallFuncParams } from '@/hooks/useAPICall'
import {
  CourseCertificate,
  DeleteContentRequest,
  GetCourseSectionsResponse,
  ReorderCourseContents,
  ReorderCourseSectionRequest,
  UpsertContentQuizRequest,
  UpsertContentReferenceRequest,
  UpsertContentTaskRequest,
  UpsertContentVideoRequest,
  UpsertCourseCertificateRequest,
  UpsertCourseSectionRequest
} from '@/types/contracts/course'
import { CreateTagRequest } from '@/types/contracts/tag'
import { AxiosError } from 'axios'

export const fetchCourseDetail = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, CourseType>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCourseDetail(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchCourseCertificateDetails = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, CourseCertificate | undefined>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCertificateDetails(data)
    resolve(res?.data)
  } catch (error: unknown) {
    const { response } = error as AxiosError
    if (response?.status === 404) {
      resolve(undefined)
      return
    }
    reject(response ?? error)
  } finally {
    progress(false)
  }
}

export const upsertCourseCertificate = async ({
  data,
  resolve,
  reject,
  progress
}: APICallFuncParams<UpsertCourseCertificateRequest, CourseCertificate>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res: any = await API_URL.course.upsertCertificate(data!)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchCourse = async ({
  query = '',
  resolve,
  reject,
  progress
}: ActionParamsGetList<string, CourseType[]>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCourse(query)
    resolve(res)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchCourseList = async ({
  data,
  resolve,
  reject,
  progress
}: APICallFuncParams<QueryCourseProps, CourseType[]>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCourseList(data)
    resolve(res.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const deleteCourse = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, any>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.deleteCourse(data)
    resolve(res)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const addEditCourse = async ({
  data,
  resolve,
  reject,
  progress
}: APICallFuncParams<UpsertCourseProps, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res: any = await API_URL.course.upsertCourse(data!)
    resolve(res.data)
  } catch (error: any) {
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  } finally {
    progress(false)
  }
}

export const fetchVideoUploadToken = async ({
  data,
  resolve,
  reject,
  progress = () => null
}: any) => {
  try {
    progress(true)
    const res: any = await API_URL.content.getVideoUploadToken(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const updateUploadVideoContentStatus = async ({
  data,
  resolve,
  reject,
  progress = () => null
}: any) => {
  try {
    progress(true)
    await API_URL.content.updateVideoContentStatus(data)
    resolve()
  } catch (error: any) {
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  } finally {
    progress(false)
  }
}

export const fetchCourseContents = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, Content[]>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCourseContents(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchCourseSections = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, GetCourseSectionsResponse>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCourseSections(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchCourseContent = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, Content>) => {
  try {
    progress(true)
    const res: any = await API_URL.course.getCourseContent(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const reorderCourseContents = async ({
  data,
  resolve,
  reject,
  progress
}: APICallFuncParams<ReorderCourseContents, any>) => {
  if (!data) {
    reject('parameters are not suplied')
    return
  }

  try {
    progress(true)
    const res: any = await API_URL.course.reorderContents(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const deleteCourseContent = async ({
  data,
  resolve,
  reject,
  progress
}: APICallFuncParams<DeleteContentRequest, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res: any = await API_URL.course.deleteContent(data!)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchTags = async ({
  progress,
  reject,
  resolve
}: APICallFuncParams<undefined, Tag[]>) => {
  try {
    progress(true)
    const res = (await API_URL.tags.getTags('')) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const createTag = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<CreateTagRequest, Tag>) => {
  try {
    progress(true)
    const res = (await API_URL.tags.postTag(data)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const upsertCourseContentVideo = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<UpsertContentVideoRequest, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = (await API_URL.course.upsertContentVideo(data!)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const upsertCourseContentTask = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<UpsertContentTaskRequest, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = (await API_URL.course.upsertContentTask(data!)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const upsertCourseContentReference = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<UpsertContentReferenceRequest, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = (await API_URL.course.upsertContentReference(data!)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const upsertCourseContentQuiz = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<UpsertContentQuizRequest, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = (await API_URL.course.upsertContentQuiz(data!)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const upsertCourseSection = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<UpsertCourseSectionRequest, any>) => {
  try {
    progress(true)
    const res = (await API_URL.course.upsertCourseSection(data)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const deleteCourseSection = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<string, any>) => {
  try {
    progress(true)
    const res = (await API_URL.course.deleteCourseSection(data)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const reorderCourseSections = async ({
  data,
  progress,
  reject,
  resolve
}: APICallFuncParams<ReorderCourseSectionRequest, any>) => {
  try {
    progress(true)
    const res = (await API_URL.course.reorderCourseSections(data)) as any
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

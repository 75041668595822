import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Menu, Button, Avatar, Space, Dropdown } from 'antd'
import { LeftOutlined, RightOutlined, MoreOutlined, LogoutOutlined } from '@ant-design/icons'
// eslint-disable-next-line no-duplicate-imports
import type { MenuProps } from 'antd'

import { RouteType } from '@/types/routes'

import { routes } from './menus'

import Images from '@/assets/images'

import Styles from './Sidebar.module.scss'
import cookieServices from '@/utils/cookieServices'
import AuthContext, { ActionTypes } from '@/modules/auth/context'
import helper from '@/utils/helper/helper'

type MenuItem = Required<MenuProps>['items'][number]

const Sidebar = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user, dispatch }: any = useContext(AuthContext)

  const [showSidebar, setShowSidebar] = useState<boolean>(true)

  const getItem = (menu :any) => {
    return {
      key: menu.key,
      icon: menu.icon,
      children: menu.children,
      label:
        menu?.route ? <Link to={menu?.route}>
          {menu?.name}
        </Link> : <a>{menu?.name}</a>,
      type: menu.type
    } as MenuItem
  }

  /**
   * Getting list of menu items
   */
  const items: MenuProps['items'] = routes.map((route: RouteType) => {
    if (route.permission && !helper.checkPermission(route.permission)) {
      return null
    }

    const routeChildren =
      route?.collapse?.map((child) => {
        if (child.permission && !helper.checkPermission(child.permission)) {
          return null
        }
        return getItem(child)
      }) ?? null
    if (Array.isArray(routeChildren) && routeChildren.filter(Boolean).length === 0) {
      return null
    }

    return getItem({ ...route, children: routeChildren })
  })

  // If path location change set default active menu
  const getPath = () => {
    const curPathName = pathname.split('/').filter((path) => path !== '')
    let currentPath = 'dashboard'
    let currentSub = ''
    if (!curPathName.length) {
      currentPath = 'dashboard'
      currentSub = ''
    } else if (curPathName.length > 1) {
      currentPath = curPathName[1]
      currentSub = curPathName[0]
    } else {
      currentPath = curPathName[0]
      currentSub = ''
    }

    return {
      currentPath,
      currentSub
    }
  }

  const [selectedKey, setSelectedKey] = useState(getPath().currentPath)
  const [openKeys, setOpenKeys] = useState(getPath().currentSub)

  useEffect(() => {
    setSelectedKey(getPath().currentPath)
    setOpenKeys(getPath().currentSub)
  }, [pathname])

  /**
   * Function for trigger navigation
   * @param e event target
   * @returns navigate or move to another page
   */
  const onClick: MenuProps['onClick'] = (e) => {
    setSelectedKey(e.key)
    if (e.keyPath.length > 1) {
      const subs = e.keyPath[1]
      setOpenKeys(subs)

      return navigate(`/${subs}/${e.key}`, { replace: true })
    }
    return navigate(e.key === 'dashboard' ? '/' : e.key, { replace: true })
  }

  const handleLogout = () => {
    cookieServices.clearCookie()
    dispatch({ type: ActionTypes.CLEAR_USER })
    navigate('/signin', { replace: true })
  }

  const accountActions =
    <Menu
      items={[
        {
          key: 'logout',
          label:
            <a onClick={handleLogout}>
              <Space>
                <LogoutOutlined /> <span>Logout</span>
              </Space>
            </a>

        }
      ]}
    />


  return (
    <div
      className={`${Styles.sidebar} ${Styles[showSidebar ? 'show' : 'hide']} sidebar`}
      style={{ width: '240px' }}
    >
      <Button
        shape="circle"
        icon={showSidebar ? <LeftOutlined /> : <RightOutlined />}
        className={Styles['sidebar-trigger']}
        size="small"
        onClick={() => setShowSidebar(!showSidebar)}
      />

      <div className={Styles['sidebar-menus']}>
        <div className={Styles['sidebar-logo']} onClick={() => navigate('/')}>
          <img src={Images.logoSaka} alt="logo-kognisi" />
        </div>
        <Menu
          onClick={onClick}
          mode="inline"
          defaultSelectedKeys={[selectedKey]}
          defaultOpenKeys={[openKeys]}
          items={items}
        />
      </div>

      {user && <div className={Styles['sidebar-account']}>
        <Dropdown overlay={accountActions}>
          <Space>
            <Avatar style={{ backgroundColor: '#FFD8BF', color: '#FA541C' }}>{user.email[0].toUpperCase()}</Avatar>
            <div>
              {user.name && <div className={Styles['sidebar-account-name']}>{user.name}</div>}
              <div className={Styles['sidebar-account-email']}>{user?.email}</div>
            </div>

            <MoreOutlined />
          </Space>
        </Dropdown>
      </div>}
    </div>
  )
}

export default Sidebar

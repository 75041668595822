import {
  DeleteContentRequest,
  GetCertificateListRequest,
  GetFeaturedCourseResponse,
  GetFeaturedCoursesResponse,
  ReorderCourseContents,
  ReorderCourseSectionRequest,
  UpdateFeaturedCourseRequest,
  UpsertContentQuizRequest,
  UpsertContentReferenceRequest,
  UpsertContentTaskRequest,
  UpsertContentVideoRequest,
  UpsertCourseCertificateRequest,
  UpsertCourseSectionRequest
} from '@/types/contracts/course'
import { GetSubmisionRequest } from '@/types/contracts/task'
import { QueryCourseProps, UpsertCourseProps } from '@/types/entity'
import methods from '../methods'

const API_URL = '/api/admin'

const getCourseDetail = (serial: string) => methods.GET_API(`${API_URL}/courses/${serial}`)
const getCertificateDetails = (serial: string) => methods.GET_API(`${API_URL}/courses/certificate/${serial}`)
const upsertCertificate = (data: UpsertCourseCertificateRequest) => methods.POST_API(`${API_URL}/courses/certificate/upsert`, data)
const getFeaturedCourses = () => methods.GET_API(`${API_URL}/courses/featured`) as unknown as GetFeaturedCoursesResponse
const getFeaturedCourse = (id: number) => methods.GET_API(`${API_URL}/courses/featured/${id}`) as unknown as GetFeaturedCourseResponse
const updateFeaturedCourse = ({ id, ...spec }: UpdateFeaturedCourseRequest) => methods.PUT_API(`${API_URL}/courses/featured/${id}`, spec)
const getCourse = (query?: string) => methods.GET_API(`${API_URL}/courses?${query}`)
const getCourseList = (data?: QueryCourseProps) => methods.GET_API(`${API_URL}/courses`, data)
const upsertCourse = (data: UpsertCourseProps) => methods.POST_API(`${API_URL}/courses/upsert`, data)
const uploadMaterialsCourse = (data: FormData) => methods.POST_API(`${API_URL}/courses/materials/uplaod`, data)
const getCourseContents = (query: string) => methods.GET_API(`${API_URL}/courses/contents?${query}`)
const getCourseContent = (serial: string) => methods.GET_API(`${API_URL}/courses/content/${serial}`)
const upsertContentVideo = (data: UpsertContentVideoRequest) => methods.POST_API(`${API_URL}/courses/content-video`, data)
const upsertContentTask = (data: UpsertContentTaskRequest) => methods.POST_API(`${API_URL}/courses/content-task`, data)
const upsertContentReference = (data: UpsertContentReferenceRequest) => methods.POST_API(`${API_URL}/courses/content-reference`, data)
const upsertContentQuiz = (data: UpsertContentQuizRequest) => methods.POST_API(`${API_URL}/courses/content-quiz`, data)
const reorderContents = (data: ReorderCourseContents) => methods.POST_API(`${API_URL}/courses/contents/reorder`, data)
const deleteContent = (query: DeleteContentRequest) => methods.DELETE_API(`${API_URL}/courses/content`, query)
const getCourseSections = (serial: string) => methods.GET_API(`${API_URL}/courses/sections/${serial}`)
const upsertCourseSection = (data?: UpsertCourseSectionRequest) => methods.POST_API(`${API_URL}/courses/sections/upsert`, data)
const deleteCourseSection = (serial?: string) => methods.DELETE_API(`${API_URL}/courses/section/${serial}`)
const deleteCourse = (serial?: string) => methods.DELETE_API(`${API_URL}/courses/${serial}`)
const reorderCourseSections = (data?: ReorderCourseSectionRequest) => methods.POST_API(`${API_URL}/courses/sections/reorder`, data)
const getTaskList = (params?: string) => methods.GET_API(`${API_URL}/courses/tasks?${params}`)
const getTaskSubmissionList = (data?: GetSubmisionRequest) => methods.GET_API(`${API_URL}/courses/${data?.courseSerial}/tasks/${data?.courseContentSerial}/submissions?${data?.params}`)
const getCertificateList = (data: GetCertificateListRequest) => methods.GET_API(`${API_URL}/courses/certificates`, data)
const getUserCertificate = (certificateSerial: string) => methods.GET_API(`${API_URL}/courses/user-certificate/${certificateSerial}`)
const getTaskDetail = (courseSerial?: string) => methods.GET_API(`${API_URL}/courses/${courseSerial}/tasks`)
const getQuizList = (params?: string) => methods.GET_API(`${API_URL}/courses/quizzes?${params}`)
const getQuizDetail = (courseSerial?: string) => methods.GET_API(`${API_URL}/courses/${courseSerial}/quizzes`)
const getQuizParticipants = (data?: GetSubmisionRequest) => methods.GET_API(`${API_URL}/courses/${data?.courseSerial}/quizzes/${data?.courseContentSerial}/participants?${data?.params}`)
const getQuizParticipantAnswer = (data?: GetSubmisionRequest) => methods.GET_API(`${API_URL}/courses/${data?.courseSerial}/quizzes/${data?.courseContentSerial}/participants/${data?.userSerial}/answer?${data?.params ?? ''}`)

export default {
  getCourseDetail,
  getCertificateDetails,
  upsertCertificate,
  getCourse,
  getCourseList,
  upsertCourse,
  uploadMaterialsCourse,
  getCourseContents,
  getCourseContent,
  upsertContentVideo,
  upsertContentTask,
  upsertContentQuiz,
  upsertContentReference,
  reorderContents,
  deleteContent,
  getCourseSections,
  upsertCourseSection,
  deleteCourseSection,
  reorderCourseSections,
  deleteCourse,
  getFeaturedCourses,
  getFeaturedCourse,
  updateFeaturedCourse,
  getTaskList,
  getTaskSubmissionList,
  getCertificateList,
  getUserCertificate,
  getTaskDetail,
  getQuizList,
  getQuizDetail,
  getQuizParticipants,
  getQuizParticipantAnswer
}

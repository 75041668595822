import React, { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { State, ActionTypes } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: State = {
  facilitator: {
    data: null,
    isLoading: false
  }
}


const FacilitatorContext = createContext<ContextType<State, Action<ActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const FacilitatorProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <FacilitatorContext.Provider value={{ ...state, dispatch }}>
    {children}
  </FacilitatorContext.Provider>

}

export default FacilitatorContext
export { ActionTypes }

import { CategoryType } from '@/types/entity'
import { Action } from '@/types/context'

export type MassageProps = {
  show: boolean | false
  type: string | null,
  message: string
}

interface CategoriesType {
  data?: CategoryType[]
  isLoading: boolean
}

export type AppState = {
  message?: MassageProps,
  categories?: CategoriesType
}

export enum AppActionTypes {
  SET_MESSAGE = 'SET_MESSAGE',
  CLEAR_MESSAGE = 'CLEAR_MESSAGE',
  SET_CATEGORIES = 'SET_CATEGORIES',
  SET_CATEGORIES_LOADING = 'SET_CATEGORIES_LOADING'
}

const reducer = (state: AppState, action: Action<AppActionTypes>): AppState => {
  switch (action.type) {
    case AppActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload
      }
    case AppActionTypes.CLEAR_MESSAGE:
      return {
        ...state,
        message: {
          show: false,
          type: null,
          message: ''
        }
      }
    case AppActionTypes.SET_CATEGORIES:
      return {
        ...state,
        categories: {
          data: action?.payload ?? [],
          isLoading: false
        }
      }
    case AppActionTypes.SET_CATEGORIES_LOADING:
      return {
        ...state,
        categories: {
          ...state?.categories,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

import React, { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { AppActionTypes, AppState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: AppState = {
  message: {
    show: false,
    type: null,
    message: ''
  },
  categories: {
    data: [],
    isLoading: false
  }
}


const AppContext = createContext<ContextType<AppState, Action<AppActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const AppProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <AppContext.Provider value={{ ...state, dispatch }}>
    {children}
  </AppContext.Provider>

}

export default AppContext
export { AppActionTypes }

import { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { UploadActionTypes, UploadState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: UploadState = {
  loading: false
}

const UploadContext = createContext<ContextType<UploadState, Action<UploadActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const UploadProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <UploadContext.Provider value={{ ...state, dispatch }}>
    {children}
  </UploadContext.Provider>
}

export default UploadContext
export { UploadActionTypes }

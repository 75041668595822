import { Banner } from '@/types/entity'
import { Action } from '@/types/context'

export interface State {
  banners: [] | Banner[]
  bannerDetail?: Banner
  loading: boolean
}

export enum ActionTypes {
  SET_BANNERS = 'SET_BANNERS',
  SET_BANNER = 'SET_BANNER',
  SET_LOADING_BANNER = 'SET_LOADING_BANNER',
  CLEAR_BANNER = 'CLEAR_BANNER'
}

const reducer = (state: State, action: Action<ActionTypes>): State => {
  switch (action.type) {
    case ActionTypes.SET_BANNERS:
      return {
        ...state,
        banners: action.payload,
        loading: false
      }
    case ActionTypes.SET_BANNER:
      return {
        ...state,
        bannerDetail: action.payload,
        loading: false
      }
    case ActionTypes.SET_LOADING_BANNER:
      return {
        ...state,
        loading: action?.payload ?? false
      }
    case ActionTypes.CLEAR_BANNER:
      return {
        banners: [],
        loading: false
      }
    default:
      return state
  }
}

export default reducer

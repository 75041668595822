import moment from 'moment'
import QueryString from 'qs'

const helper = (() => {
  /**
   * Function to check user has access permissions
   * @param permisson string
   * @returns boolean
   */
  const checkPermission = (permisson: string) => {
    const permissions = localStorage.getItem('permissions')?.split(',') ?? []
    return permissions.includes(permisson)
  }

  const dateFormat = (date:string|Date, format: string) => moment(new Date(date)).format(format)

  /**
   * Function to check email is valid
   * @param {String} email
   * @returns booelan fo is email
 */
  const checkIsEmailValid = (email: string) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(String(email).toLowerCase())
  }

  /**
   * Function to replace value to be string of number
   * @param {String | Number} value string|number
   * @returns string of number [0-9]
 */
  const numberOnly = (value: string|number) => {
    return value.toString().replace(/[^0-9]/g, '')
  }

  const checkIsNumber = (number: any) => {
    return !isNaN(number)
  }

  /**
   * Function to check is url valid
   * @param {String} str string
   * @returns boolean
 */
  const checkIsUrl = (str: string) => {
    const regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
    if (regexp.test(str)) {
      return true
    }

    return false
  }

  /**
   * Function to return string of query params
   * @param value of object
   * @returns string of query params
   */
  const stringified = (value: object) => {
    return QueryString.stringify(value)
  }

  function labelizeKeyValue(kv: { serial: string, name: string }) {
    return { label: kv.name, value: kv.serial }
  }

  function extractValue(kv: { value: string }) {
    return kv?.value
  }

  function filterOptions(input: string, option: { label: string } | undefined) {
    return !!option?.label.toLowerCase().includes(input.toLowerCase())
  }

  function reformatMoney(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  function secondsToDuration(secs: number) {
    const rounded = Math.ceil(secs)
    const hh = Math.floor(rounded / 3600)
    const mm = Math.floor(rounded % 3600 / 60)
    const ss = rounded % 60

    const pad = (num: number) => {
      return String(num).padStart(2, '0')
    }

    // Format the result as hh:mm:ss
    const formattedTime = `${pad(hh)}:${pad(mm)}:${pad(ss)}`

    return formattedTime
  }

  function getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video')
      video.preload = 'metadata'
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        resolve(video.duration)
      }
      video.src = URL.createObjectURL(file)
      video.onerror = () => {
        reject(new Error('failed reading video duration'))
      }
    })
  }

  function currencyFormat(value: number | string) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return {
    checkPermission,
    dateFormat,
    checkIsEmailValid,
    numberOnly,
    checkIsNumber,
    checkIsUrl,
    stringified,
    labelizeKeyValue,
    filterOptions,
    extractValue,
    reformatMoney,
    secondsToDuration,
    getVideoDuration,
    currencyFormat
  }
})()

export default helper

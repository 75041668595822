import methods from '../methods'

export type BannerSerialType = {
  serial: string;
}

export type BannerPutProps = {
  serial: string;
  imageTitle: string;
  imageURL: string;
  isActive: boolean;
  redirectURL: string;
  reason: string;
}

const API_URL = '/api/admin'

const getBanners = () => methods.GET_API(`${API_URL}/banners`)
const getBannerDetail = (serial: BannerSerialType) => methods.GET_API(`${API_URL}/banner/${serial}`)
const updateBannerDetail = (serial: BannerSerialType, data: BannerPutProps) => methods.PUT_API(`${API_URL}/banner/${serial}`, data)

export default {
  getBanners,
  getBannerDetail,
  updateBannerDetail
}

import API_URL from '@/services/api'
import { ActionParams } from '@/types/context'
import { UploadFileProps, UploadFileWithTypeProps } from '../../services/api/upload'
import { UploadedFile } from '../../types/entity'

interface UploadImageResponse {
  code: number
  data: {
    url: string
    name: string
    size: number
    type: string
  }
  message: string
  meta: string
}

interface UploadFileResponse {
  code: number
  data: {
    url: string
    name: string
    size: number
    type: string
  }
  message: string
  meta: string
}

export const uploadImage = async ({
  data,
  resolve,
  reject,
  progress
}: ActionParams<UploadFileProps, UploadedFile>) => {
  try {
    progress(true)
    const res = await API_URL.upload.uploadImage(data) as UploadImageResponse
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const uploadFile = async ({
  data,
  resolve,
  reject,
  progress
}: ActionParams<UploadFileWithTypeProps, UploadedFile>) => {
  try {
    progress(true)
    const res = await API_URL.upload.uploadFile(data) as UploadFileResponse
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

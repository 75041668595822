import { ReactNode } from 'react'
import Form, { Rule } from 'antd/lib/form'
import { AtomsSkeleton } from '@/components/atoms'

interface PropTypes {
  isLoading: boolean
  isViewOnly?: boolean
  label: string
  name: string
  value?: any
  rules: Rule[]
  component: ReactNode
  isImage?: boolean
  isHtmlContent?: boolean
  valuePropName?: string
}

const FormField = ({
  isLoading,
  label,
  name,
  rules,
  component,
  value,
  isViewOnly,
  isImage,
  isHtmlContent,
  valuePropName
}: PropTypes) => {
  const isComponent = isImage || isHtmlContent ? component : value
  const fieldComponent = isViewOnly ? isComponent : component
  return (
    isViewOnly ?
      <Form.Item label={label}>
        {isLoading ? <AtomsSkeleton /> : fieldComponent}
      </Form.Item>
      : <Form.Item label={label} name={name} rules={rules} valuePropName={valuePropName}>
        {isLoading ? <AtomsSkeleton /> : fieldComponent}
      </Form.Item>
  )
}

export default FormField

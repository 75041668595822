import React, { ReactNode } from 'react'
import Styles from './Card.module.scss'

interface PropTypes {
  children?: ReactNode
}

const AtomsCard = (props: PropTypes) => {
  const { children } = props
  return (
    <div className={`card ${Styles.card}`}>{children}</div>
  )
}

export default AtomsCard

import { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { CourseActionTypes, CourseState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: CourseState = {
  tags: {
    data: [],
    isLoading: false
  },
  course: {
    isLoading: false
  },
  courses: {
    data: [],
    isLoading: false
  }
}

const CourseContext = createContext<ContextType<CourseState, Action<CourseActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const CourseProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <CourseContext.Provider value={{ ...state, dispatch }}>
    {children}
  </CourseContext.Provider>

}

export default CourseContext
export { CourseActionTypes }

import { User } from '@/types/entity'

export interface State {
  authed: boolean
  permissions: string[],
  user?: User | null,
  loading: boolean
}

export enum ActionTypes {
  SET_USER = 'SET_USER',
  SET_AUTH = 'SET_AUTH',
  SET_PERMISSIONS = 'SET_PERMISSIONS',
  SET_LOADING_USER = 'SET_LOADING_USER',
  CLEAR_USER = 'CLEAR_USER'
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload,
        authed: true,
        loading: false
      }
    case ActionTypes.SET_AUTH:
      return {
        ...state,
        authed: action?.payload ?? false
      }
    case ActionTypes.SET_PERMISSIONS:
      return {
        ...state,
        permissions: action?.payload ?? []
      }
    case ActionTypes.SET_LOADING_USER:
      return {
        ...state,
        loading: action?.payload ?? false
      }
    case ActionTypes.CLEAR_USER:
      return {
        ...state,
        user: null,
        authed: false,
        loading: false
      }
    default:
      return state
  }
}

export default reducer

import { Note } from '@/types/entity'
import { Action } from '@/types/context'

export enum ActionTypes {
  SET_NOTES = 'SET_NOTES',
  APPEND_NOTES = 'APPEND_NOTES',
  SET_NOTES_LOADING = 'SET_NOTES_LOADING'
}

export interface State {
  notes: Note[]
  loading: boolean
}

const reducer = (state: State, action: Action<ActionTypes>): State => {
  switch (action.type) {
    case ActionTypes.SET_NOTES:
      return {
        ...state,
        notes: action?.payload,
        loading: false
      }
    case ActionTypes.APPEND_NOTES:
      return {
        ...state,
        loading: false,
        notes: [...state.notes, ...action.payload]
      }
    case ActionTypes.SET_NOTES_LOADING:
      return {
        ...state,
        loading: action?.payload ?? false
      }
    default:
      return state
  }
}

export default reducer

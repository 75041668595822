import React from 'react'

import Styles from './SplashScreen.module.scss'

function SplashScreen() {
  return (
    <div className={Styles.SplashScreen}>
      <div className={Styles.loader}>
        <div className={Styles['loader-dot']} />
        <div className={Styles['loader-dot']} />
        <div className={Styles['loader-dot']} />
        <div className={Styles['loader-dot']} />
        <div className={Styles['loader-dot']} />
        <div className={Styles['loader-dot']} />
        {/* <div className={Styles['loader-text']} /> */}
      </div>
    </div>
  )
}

export default SplashScreen

import { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { TaskActionTypes, TaskState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: TaskState = {
  taskSubmission: {
    data: undefined,
    isLoading: false
  }
}

const TaskContext = createContext<ContextType<TaskState, Action<TaskActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const TaskProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <TaskContext.Provider value={{ ...state, dispatch }}>
    {children}
  </TaskContext.Provider>

}

export default TaskContext
export { TaskActionTypes }

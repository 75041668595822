import { TransactionType } from '@/types/entity'
import { Action } from '@/types/context'

export enum TransactionActionTypes {
  SET_TRANSACTION_LIST = 'SET_TRANSACTION_LIST',
  SET_TRANSACTION_LIST_LOADING = 'SET_TRANSACTION_LIST_LOADING',
  SET_TRANSACTION_DETAIL = 'SET_TRANSACTION_DETAIL',
  SET_TRANSACTION_DETAIL_LOADING = 'SET_TRANSACTION_DETAIL_LOADING',
}

export interface TransactionState {
  transactionList: { data?: TransactionType[], meta: any, isLoading: boolean },
  transactionDetail: { data?: TransactionType | null, isLoading: boolean }
}

const reducer = (state: TransactionState, action: Action<TransactionActionTypes>): TransactionState => {
  switch (action.type) {
    case TransactionActionTypes.SET_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: {
          data: action?.payload?.data,
          meta: action?.payload?.meta,
          isLoading: false
        }
      }
    case TransactionActionTypes.SET_TRANSACTION_LIST_LOADING:
      return {
        ...state,
        transactionList: {
          ...state.transactionList,
          isLoading: action?.payload ?? false
        }
      }
    case TransactionActionTypes.SET_TRANSACTION_DETAIL:
      return {
        ...state,
        transactionDetail: {
          data: action?.payload?.data,
          isLoading: false
        }
      }
    case TransactionActionTypes.SET_TRANSACTION_DETAIL_LOADING:
      return {
        ...state,
        transactionDetail: {
          ...state.transactionDetail,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

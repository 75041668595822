import { useEffect, useState } from 'react'
import { Button, Divider, message, Select, SelectProps } from 'antd'

import { useAPICall } from '@/hooks/useAPICall'
import { createTag, fetchTags } from '@/modules/course/CourseList/context/action'
import helper from '@/utils/helper/helper'
import { useDidUpdateEffect } from '@/hooks/useDidUpdateEffect'

function customDropdownRender(value: string | undefined, createNewTag: (val: string) => void) {
  return (menu: React.ReactElement) =>
    <>
      {menu}
      {value && <>
        <Divider style={{ margin: 0 }} />
        <Button
          block
          className="mt-0.5 !text-[#2A9D8C] !font-bold !text-left"
          type="text"
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => createNewTag(value)}
        >
          Add New &apos;{value}&apos;
        </Button>
      </>}
    </>
}

const TagsSelector = (props: SelectProps) => {
  const [keyword, setKeyword] = useState<string>()
  const [selected, setSelected] = useState<any[]>(props.value)

  const { data: tags, loading: tagsLoading, trigger: loadTags } = useAPICall(fetchTags)
  const { loading: creatingTag, trigger: createATag } = useAPICall(createTag)

  useEffect(() => loadTags({}), [])

  const options = tags?.map((tag) => ({ label: tag.name, value: tag.serial }))

  useDidUpdateEffect(() => {
    if (props.onChange) {
      props.onChange(selected, options || [])
    }
  }, [selected])

  const onTagSelect = ({ label, value }: any) => {
    if (selected.length === 2) {
      message.error('Cannot select more than 2 tags!')
      return
    }
    setSelected([...selected, { label, value }])
  }

  const onTagDeselect = ({ value }: any) => {
    const newSelected = selected.filter(({ value: val }) => val !== value)
    setSelected(newSelected)
  }

  const createNewTag = (name: string) => {
    createATag({
      request: { name },
      successCallback(tag) {
        onTagSelect({ label: tag.name, value: tag.serial })
        loadTags({})
      }
    })
    setKeyword(undefined)
  }

  return (
    <Select
      {...props}
      labelInValue
      showSearch
      className="w-full"
      mode="multiple"
      placeholder="Select tag (max 2)"
      size="large"
      onSelect={onTagSelect}
      onDeselect={onTagDeselect}
      value={selected}
      loading={tagsLoading || creatingTag}
      disabled={tagsLoading || creatingTag}
      options={options}
      filterOption={helper.filterOptions as any}
      searchValue={keyword}
      onSearch={setKeyword}
      dropdownRender={customDropdownRender(keyword, createNewTag)}
    />
  )
}

export default TagsSelector

import React, { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { State, ActionTypes } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: State = {
  banners: [],
  loading: false
}

const BannerContext = createContext<ContextType<State, Action<ActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const BannerProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <BannerContext.Provider value={{ ...state, dispatch }}>
    {children}
  </BannerContext.Provider>

}

export default BannerContext
export { ActionTypes }

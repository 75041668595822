import React, { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import cookieServices from '@/utils/cookieServices'
import reducer, { State, ActionTypes } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: State = {
  authed: !!cookieServices.getToken() || false,
  permissions: localStorage.getItem('permissions')?.split(',') ?? [],
  user: cookieServices.getSession() || null,
  loading: false
}

const AuthContext = createContext<ContextType<State, Action<ActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const AuthProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <AuthContext.Provider value={{ ...state, dispatch }}>
    {children}
  </AuthContext.Provider>

}

export default AuthContext
export { ActionTypes }

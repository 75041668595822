import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, Select, SelectProps } from 'antd'
import { debounce } from 'lodash'
import { ReactNode, useState } from 'react'
import { useDidUpdateEffect } from '@/hooks/useDidUpdateEffect'
import { fetchUsers } from '@/modules/users/User/context/action'
import { Facilitator, User } from '@/types/entity'
import helper from '@/utils/helper/helper'
import FacilitatorCreationForm from '../FacilitatorCreationForm'

interface Props extends SelectProps {
  onChange?: (val?: { label: string, value: string } | null) => void
}

function customDropdownRender(menu: React.ReactElement) {
  return (
    <>
      {menu}
      <Divider style={{ margin: 0 }} />
      <Button
        className="!flex !items-center !justify-center mt-0.5 !text-[#2A9D8C] !font-bold"
        type="text"
        block href="/users/user"
        target="_blank"
      >
        <PlusOutlined />
        Add New User
      </Button>
    </>
  )
}

const SEARCH_KEYWORD_MINIMUM_WARNING = 'Please type at least 3 letters'

interface ISelectValue {
  value: string
  key: string
  label: string
}

const FacilitatorsSelector = ({ onChange = (val) => val, ...rest }: Props) => {
  const [facilitatorToCreate, setFacilitatorToCreate] = useState<any>()
  const [options, setOptions] = useState<User[]>([])
  const [searching, setSearching] = useState(false)
  const [value, setValue] = useState<ISelectValue | null>(null)
  const [facilitatorSerial, setFacilitatorSerial] = useState<string>()

  const [notFoundContent, setNotFoundContent] = useState<ReactNode>(SEARCH_KEYWORD_MINIMUM_WARNING)

  const onSelect = (selected: ISelectValue, option: any) => {
    setValue({
      ...selected,
      label: Array.isArray(selected.label) ? selected.label.join('') : selected.label
    })
    const user = option['data-user-serial']
    if (user?.facilitatorSerial) {
      setFacilitatorSerial(user.facilitatorSerial)
      return
    }
    setFacilitatorToCreate(user)
    setNotFoundContent(SEARCH_KEYWORD_MINIMUM_WARNING)
  }

  const onSearch = debounce((search: string) => {
    if (search.trim().length < 3) {
      setNotFoundContent(SEARCH_KEYWORD_MINIMUM_WARNING)
      return
    }

    setNotFoundContent(undefined)
    fetchUsers({
      params: search ? helper.stringified({ search }) : undefined,
      progress: (loading: boolean) => setSearching(loading),
      resolve: (data: any) => setOptions(data)
    })
  }, 500)

  useDidUpdateEffect(() => {
    if (!facilitatorSerial || !value) {
      return
    }
    onChange({
      label: value?.label,
      value: facilitatorSerial
    })
  }, [facilitatorSerial])

  return (
    <>
      <Select
        showSearch
        labelInValue
        placeholder="Select user"
        onSearch={onSearch}
        dropdownRender={(menu) => customDropdownRender(menu)}
        loading={searching}
        notFoundContent={notFoundContent}
        value={value}
        onBlur={() => setOptions([])}
        filterOption={false}
        defaultActiveFirstOption={false}
        onSelect={onSelect}
        {...rest}
      >
        {options.map((option: any) =>
          <Select.Option key={option.serial} value={option.serial} data-user-serial={option}>
            {/* avoid "Warning: `label` of `value` is not same as `label` in Select options." */}
            {`${option.fullName} (${option.serial})`}
          </Select.Option>
        )}
      </Select>

      <Modal
        destroyOnClose
        visible={facilitatorToCreate}
        title="Add New Facilitator Profile"
        closable={false}
        footer={false}
        zIndex={2000}
      >
        <FacilitatorCreationForm
          userData={facilitatorToCreate}
          onCancel={() => {
            setFacilitatorToCreate(undefined)
            setValue(null)
          }}
          onSaved={(facilitator: Facilitator) => {
            setFacilitatorSerial(facilitator.serial)
            setFacilitatorToCreate(undefined)
          }}
        />
      </Modal>
    </>
  )
}

export default FacilitatorsSelector

import { AtomsTablePagination } from '@/components/atoms'
import { useAPICall } from '@/hooks/useAPICall'
import { fetchFacilitatorCourses } from '@/modules/users/Facilitator/context/action'
import { CourseFacilitatorType } from '@/types/contracts/facilitators'
import helper from '@/utils/helper/helper'
import { Card, Table } from 'antd'
import { TableProps } from 'antd/es/table'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PropTypes, QueryType } from './types'

const FacilitatorCourse = ({ facilitatorSerial }: PropTypes) => {
  const { trigger: getCourse, data: courseList, loading } = useAPICall(fetchFacilitatorCourses)

  const [query, setQuery] = useState<QueryType>({
    page: 1,
    size: 10,
    search: '',
    sortBy: '',
    sortType: ''
  })

  const columns: any = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (_v: any, _r: any, idx: number) => (query.page - 1) * query.size + idx + 1
    },
    {
      title: 'Course ID',
      dataIndex: 'courseSerial',
      key: 'courseSerial',
      sorter: (a: any, b: any) => a?.courseSerial - b?.courseSerial,
      render: (text: string) => <Link to={`/course/course-list/detail/${text}`}>{text}</Link>
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: (a: any, b: any) => a?.title - b?.title
    }
  ]

  const onChangePageSize = (size: number) => {
    setQuery(query => ({
      ...query,
      page: 1,
      size
    }))

  }

  const onChangePage = (page: number) => {
    setQuery(query => ({
      ...query,
      page
    }))

  }

  // const triggerSearch = debounce((value: string) => {
  //   setQuery(existingValue => ({
  //     ...existingValue,
  //     page: 1,
  //     search: value
  //   }))

  // }, 500)

  const removeUnusedQuery = () => {

    const newQuery = query
    if (isEmpty(newQuery.sortBy)) {
      delete newQuery.sortBy
    }
    if (isEmpty(newQuery.sortType)) {
      delete newQuery.sortType
    }

    return newQuery
  }

  const fetchCourseList = () => {
    const newQuery = removeUnusedQuery()

    getCourse({
      request: {
        serial: facilitatorSerial,
        params: helper.stringified({
          ...newQuery,
          serial: facilitatorSerial
        })
      }
    })
  }

  const onSorting = (field: string, order: string) => {
    let sortType: string | null = null
    switch (true) {
      case order === 'descend':
        sortType = 'desc'
        break
      case order === 'ascend':
        sortType = 'asc'
        break
      default:
        sortType = null
        break
    }
    setQuery((existingValue: any) => ({
      ...existingValue,
      sortBy: field,
      sortType
    }))
  }

  const onChangeTable: TableProps<CourseFacilitatorType>['onChange'] = (_, __, sorter: any) => {
    const { key } = sorter.column ?? {}
    onSorting(key, sorter.order)
  }

  useEffect(() => {
    fetchCourseList()
  }, [query, facilitatorSerial])
  return (
    !isEmpty(courseList?.data) ?
    <Card>
      <div className="mb-2" style={{ color: 'rgba(41, 44, 49, .68)' }}>Course List</div>
      <Table
        rowKey="courseSerial"
        columns={columns}
        dataSource={courseList?.data ?? []}
        pagination={false}
        loading={loading}
        onChange={onChangeTable}
      />

      {courseList?.data?.length && !loading && <AtomsTablePagination
        current={query.page}
        pageSize={query.size}
        total={courseList?.meta?.total}
        dataLength={courseList?.data?.length}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
      />}
    </Card>
    : <></>
  )
}

export default FacilitatorCourse

import axios from 'axios'

export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'http://localhost:8080'

axios.defaults.baseURL = API_DOMAIN


const HTTP = {
  request(method: string, url: string, data?: any, headers: any = {}, params?: any) {
    // perform axios request
    return axios.request({
      url,
      data,
      params,
      method,
      headers: Object.assign(
        {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        headers
      )
    })
  },
  requestPost(method: string, url: string, data?: any, headers: any = {}) {
    // perform axios request
    return axios.request({
      url,
      data,
      method,
      headers: Object.assign(
        {
          'Content-Type': 'application/json'
        },
        headers
      )
    })
  },
  requestUpload(method: string, url: string, data?: any) {
    return axios.request({
      url,
      data,
      method,
      headers: Object.assign(
        {
          'Content-Type': 'multipart/form-data'
        }
      )
    })
  },
  get(url: string, params?: any, headers: any = {}) {
    return this.request('get', `${url}`, null, headers, params)
  },
  post(url: string, data?: any, headers: any = {}) {
    return this.request('post', `${url}`, data, headers)
  },
  put(url: string, data?: any, headers: any = {}) {
    return this.request('put', `${url}`, data, headers)
  },
  patch(url: string, data?: any, headers: any = {}) {
    return this.requestPost('patch', `${url}`, data, headers)
  },
  delete(url: string, data?: any, headers: any = {}, params: any = {}) {
    return this.request('delete', `${url}`, data, headers, params)
  }
}


export default HTTP

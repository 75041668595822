import logoKognisi from './logo-kognisi.png'
import logoSaka from './logo-saka.png'
import goodTeams from './good-teams.svg'
import iconKognisi from './icon-kognisi.png'
import nonExistent from './404.svg'
import unauthorized from './401.svg'
import courseContentTypeVideo from './course-content-types/video.svg'
import courseContentTypeTask from './course-content-types/task.svg'
import courseContentTypeQuiz from './course-content-types/quiz.svg'
import courseContentTypeReference from './course-content-types/reference.svg'

export default {
  logoKognisi,
  goodTeams,
  iconKognisi,
  logoSaka,
  nonExistent,
  unauthorized,

  courseContentTypeVideo,
  courseContentTypeTask,
  courseContentTypeQuiz,
  courseContentTypeReference
}

import React, { Suspense, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { Sidebar } from '@molecules'
import { SplashScreen } from '@atoms'

import AllRoutes from './routes'
import { GlobalContextProvider } from './context/GlobalContextProvider'
import ProtectedRoute from './utils/helper/ProtectedRoute'

const WHITELISTED_PATHS = new Set(['signin', '404', '401', 'validate-admin-invitation'])

function App() {
  const { pathname } = useLocation()

  const getPathName = (path:string) => {
    return path.replace('/', '')
  }
  const [currentPath, setCurrentPath] = useState(getPathName(pathname))


  // Setting page scroll to 0 when changing the route\
  useEffect(() => {
    document.documentElement.scrollTop = 0
    if (document.scrollingElement !== null) {
      document.scrollingElement.scrollTop = 0
    }
    setCurrentPath(getPathName(pathname))
  }, [pathname])

  return (
    <GoogleOAuthProvider clientId="54333527757-cjah00q9a6d6l3i1p0236li1lj69gv38.apps.googleusercontent.com">
      <GlobalContextProvider>
        <ProtectedRoute whitelistedPaths={WHITELISTED_PATHS}>
          <div className="kg">
            <div className="flex">
              <Suspense fallback={<SplashScreen />}>
                { !WHITELISTED_PATHS.has(currentPath) && <Sidebar />}
                <AllRoutes />
              </Suspense>
            </div>
          </div>
        </ProtectedRoute>
      </GlobalContextProvider>
    </GoogleOAuthProvider>
  )
}

export default App

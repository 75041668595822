import { Editor } from '@tinymce/tinymce-react'
import { Spin } from 'antd'
import { useState } from 'react'

const APIKey = process.env.REACT_APP_TINYMCE_APIKEY

interface Props {
  placeholder?: string
  onChange?: (t: string) => void
  defaultValue?: string
  // TODO: add more
}

const WysiwygTextBox = ({ defaultValue, placeholder, onChange = (t) => t, ...rest }: Props) => {
  const [loading, setLoading] = useState<boolean>(true)

  const onChangeEditor = (val: string) => {
    onChange(val)
  }

  return (
    <>
      {loading && <Spin />}
      <Editor
        initialValue={defaultValue}
        apiKey={APIKey}
        init={{
          placeholder,
          menubar: false,
          statusbar: false,
          paste_as_text: true,
          forced_root_block : 'div',
          plugins: 'advlist autolink lists link autoresize',
          toolbar:
            `undo redo |
            bold italic |
            alignleft aligncenter |
            bullist numlist | link`
        }}
        onEditorChange={onChangeEditor}
        onInit={() => setLoading(false)}
        {...rest}
      />
    </>
  )
}

export default WysiwygTextBox

import axios from 'axios'
import { isEmpty } from 'lodash'

import cookieServices from '../cookieServices'
import { API_DOMAIN } from '@/services/http'

export function jwtInterceptor() {
  axios.defaults.baseURL = API_DOMAIN
  axios.interceptors.request.use((req: any) => {
    const token = cookieServices.getToken()

    if (!isEmpty(token)) {
      req.headers.Authorization = token
    }


    return req
  })
}

export function errorInterceptor() {
  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    (error: any) => {
      if (error?.response?.status === 401) {
        cookieServices.clearCookie()
        window.location.href = '/401'
      }

      return Promise.reject(error)
    }
  )
}

import API_URL from '@/services/api'
import { APICallFuncParams } from '@/hooks/useAPICall'
import { LearnerTypes } from '@/types/entity'
import { GetLearnerParams, GetLearnersRequest, GetUserCoursesRequest, LearnerPayload, UserCourse } from '@/types/contracts/user'
import { BasePaginatedResponse, BaseResponse } from '@/types/contracts/base'

export const fetchOccupations = async ({ resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.occupation.getOccupations()
    progress(false)
    resolve(res?.data?.map((item: any) => {
      item.label = item.name
      item.value = item.name
      return item
    }) ?? [])
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const fetchStudyReason = async ({ resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.studyReason.getStudyReason()
    progress(false)
    resolve(res?.data?.map((item: any) => {
      item.label = item.name
      item.value = item.name
      return item
    }) ?? [])
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const fetchTopics = async ({ resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.topic.getTopics()
    progress(false)
    resolve(res?.data?.map((item: any) => {
      item.label = item.name
      item.value = item.name
      return item
    }) ?? [])
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const fetchFields = async ({ resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.fields.getFields()
    progress(false)
    resolve(res?.data?.map((item: any) => {
      item.label = item.name
      item.value = item.name
      return item
    }) ?? [])
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const fetchLearners = async ({ params = '', resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.users.getLearners(params)
    const data = res?.data?.map((item: any, index: number) => {
      item.key = item.serial

      const no = index + 1
      if (res?.meta?.page <= 1) {
        item.no = no
      } else {
        item.no = no + (res?.meta?.page - 1) * res?.meta?.pageSize
      }
      return item
    }) ?? []
    progress(false)
    resolve({ data, meta: res?.meta })
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const fetchLearnersList = async ({
  data: req,
  resolve,
  reject,
  progress
}: APICallFuncParams<GetLearnersRequest, BasePaginatedResponse<LearnerTypes[]>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res: any = await API_URL.users.getLearnersList(req!)
    resolve(res)
  } catch (error: any) {
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  } finally {
    progress(false)
  }
}

export const fetchLearner = async ({ serial = '', resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.users.getLearner(serial)
    progress(false)
    resolve(res?.data)
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const getLearnerDetail = async ({
  data,
  resolve,
  reject,
  progress
}: APICallFuncParams<GetLearnerParams, any>) => {
  try {
    progress(true)
    const res: any = await API_URL.users.getLearner(data?.serial)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const updateLearner = async ({ serial = '', data, resolve, reject, progress }: any) => {
  try {
    progress(true)
    await API_URL.users.editLearner(serial, data)
    resolve()
    progress(false)
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const removeLearner = async ({ data, resolve, reject, progress }: any) => {
  try {
    progress(true)
    await API_URL.users.deleteLearner(data)
    resolve()
    progress(false)
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const fetchUsers = async ({ params = '', resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res = await API_URL.users.getUsers(params) as any
    resolve(res?.data ?? [])
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchUserCourses = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<GetUserCoursesRequest, BasePaginatedResponse<UserCourse[]>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.users.getUserCourses(data!)
    resolve(res as BasePaginatedResponse<UserCourse[]>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchUserCoursesTotalDuration = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<string, BaseResponse<number>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.users.getUserCoursesTotalDuration(data!)
    resolve(res as BaseResponse<number>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const createNewLearner = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<LearnerPayload, BaseResponse<any>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.users.addLearner(data)
    resolve(res as BaseResponse<any>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

import API_URL from '@/services/api'
import { CreateNoteProps, GetNotesProps } from '@/services/api/notes'
import { ActionParams } from '@/types/context'
import { Note } from '@/types/entity'

export const getNotes = async ({ data, resolve, reject, progress }: ActionParams<GetNotesProps, Note[]>) => {
  try {
    progress(true)
    const res: any = await API_URL.notes.getNotes(data)
    progress(false)
    resolve(res.data)
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const addNote = async ({ data, resolve, reject, progress }: ActionParams<CreateNoteProps, any>) => {
  try {
    progress(true)
    const res: any = await API_URL.notes.createNote(data)
    resolve(res.code)
  } catch (error: any) {
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  } finally {
    progress(false)
  }
}


import methods from '../methods'

const API_URL = '/api/admin'

interface UpdateVideoContentTypes {
  attachmentSerial: string
  isUploadSuccess: boolean
}

const getVideoUploadToken = (duration: number) => methods.GET_API(`${API_URL}/contents/videos/upload?duration=${duration}`)
const updateVideoContentStatus = (data: UpdateVideoContentTypes) => methods.PUT_API(`${API_URL}/contents/videos/upload`, data)

export default {
  getVideoUploadToken,
  updateVideoContentStatus
}

import { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { TransactionActionTypes, TransactionState } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: TransactionState = {
  transactionList: {
    data: [],
    meta: null,
    isLoading: false
  },
  transactionDetail: {
    data: null,
    isLoading: false
  }
}

const TransactionContext = createContext<ContextType<TransactionState, Action<TransactionActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const TransactionProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <TransactionContext.Provider value={{ ...state, dispatch }}>
    {children}
  </TransactionContext.Provider>

}

export default TransactionContext
export { TransactionActionTypes }

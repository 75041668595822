import React, { ReactNode, useEffect, useState } from 'react'

import { Form, Input, Select } from 'antd'
import { FormDataTypes } from '@/types/entity'

interface PropTypes {
  children?: ReactNode
  formData?: FormDataTypes
  dataState?: any
  onFinish?: any
  onFinishFailed?: any
  onFieldsChange?: any
}

const { Option } = Select

const GeneralForm = ({
  children,
  dataState,
  formData,
  onFinish,
  onFinishFailed
}: PropTypes) => {
  const [form] = Form.useForm()

  const [formState, setFormState] = useState(formData?.dataState)

  useEffect(() => {
    form.setFieldsValue({
      ...dataState
    })

    setFormState((existingValue: any) => ({
      ...existingValue,
      ...formData?.dataState
    }))


  }, [dataState])


  return (
    <Form
      name={formData?.id}
      form={form}
      layout="vertical"
      initialValues={formState}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {
        formData?.jsonForm?.map((item: any) =>
          <Form.Item
            key={item?.key}
            label={item?.label}
            name={item?.key}
            rules={formData.rules[item.key as keyof typeof formData.rules]}
          >
            { item.component === 'input' &&
              <Input
                placeholder={item?.placeholder}
                size={item?.size}
                type={item.type}
              />
            }

            { item.component === 'select' &&
              <Select
                placeholder={item?.placeholder}
                size={item?.size}
                loading={item?.loading ?? false}
              >
                { item?.options?.length &&
                  item?.options?.map((option: any) => <Option key={option.value} value={option.value}>{option.label}</Option>)
                }
              </Select>
            }

            { item.component === 'select-multiple-tag' &&
              <Select
                mode="multiple"
                placeholder={item?.placeholder}
                size={item?.size}
                loading={item?.loading ?? false}
              >
                { item?.options?.length &&
                  item?.options?.map((option: any) => <Option key={option.value} value={option.value}>{option.label}</Option>)
                }
              </Select>
            }
          </Form.Item>
        )
      }

      { formData?.actions && <Form.Item>{ formData?.actions }</Form.Item> }

      {children}
    </Form>
  )
}

export default GeneralForm

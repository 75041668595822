import { Button } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useState } from 'react'

import styles from './NoteReasonForm.module.scss'

interface PropTypes {
  onPublish: (reason: string) => void
  onCancel: () => void
}

const NoteReasonForm = ({ onCancel, onPublish }: PropTypes) => {
  const [reason, setReason] = useState('')

  return (
    <>
      <div className={styles['textarea-label']}>
        Write down the reason why you made the change<span>*</span>
      </div>
      <TextArea rows={3} onChange={({ currentTarget: { value } }) => setReason(value)} />
      <div className={styles.buttons}>
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button disabled={!reason} type="primary" onClick={() => onPublish(reason)}>
          Publish
        </Button>
      </div>
    </>
  )
}

export default NoteReasonForm

import React, { useEffect, useState } from 'react'

import { Pagination, Select } from 'antd'

import Styles from './TablePagination.module.scss'

const { Option } = Select

interface PropTypes {
  pageSizeOptions?: number[]
  defaultPageSize?: number
  pageSize?: number
  defaultCurrent?: number
  current?: number
  total?: number
  dataLength?: number
  onChangePage?: any
  onChangePageSize?: any
}

const TablePagination = (props: PropTypes) => {
  const {
    pageSizeOptions = [10, 20, 50, 100],
    defaultPageSize = 10,
    pageSize = 10,
    defaultCurrent = 1,
    current = 1,
    total = 0,
    dataLength = 0,
    onChangePage,
    onChangePageSize
  } = props

  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(0)

  const setFromToData = () => {
    if (current <= 1) {
      setFrom(current)
      setTo(dataLength)
    } else {
      setFrom(1 + (current - 1) * pageSize)
      setTo(dataLength + (current - 1) * pageSize)
    }
  }

  useEffect(() => {
    setFromToData()
  }, [current])

  return (
    <div className={Styles.tablePagination}>
      <div>
        <span className="mr-1">Show rows per page: </span>
        <Select
          defaultValue={defaultPageSize}
          value={pageSize}
          style={{ width: 82 }}
          onSelect={onChangePageSize}
        >
          {pageSizeOptions.map((item, index) =>
            <Option key={`${index}-${item}`} value={item}>
              {item}
            </Option>
          )}
        </Select>
      </div>
      <div>{`${from} - ${to} from ${total}`}</div>
      <Pagination
        className={Styles['ant-pagination']}
        defaultCurrent={defaultCurrent}
        current={current}
        total={total}
        pageSize={pageSize}
        showLessItems={true}
        onChange={onChangePage}
      />
    </div>
  )
}

export default TablePagination

import React, { useContext } from 'react'
import { GoogleLogin } from '@react-oauth/google'

import Styles from './ButtonSSOGoogle.module.scss'
import { message } from 'antd'
import { useAPICall } from '@/hooks/useAPICall'
import { fetchPermissions, loginSSO } from '@/modules/auth/context/action'
import { LoginSSOResponse } from '@/types/contracts/auth'
import AppContext from '@/context/App'
import AuthContext, { ActionTypes } from '@/modules/auth/context'
import { useNavigate } from 'react-router-dom'
import cookieServices from '@/utils/cookieServices'

function ButtonSSOGoole() {
  const navigate = useNavigate()

  const { trigger: login } = useAPICall(loginSSO)

  const { dispatch: dispatchApp }: any = useContext(AppContext)
  const { dispatch: dispatchAuth }: any = useContext(AuthContext)

  return (
    <div className={Styles['button-sso-google']}>
      <h2 className={Styles['signin-title']}>Sign In</h2>

      <GoogleLogin
        onSuccess={({ credential: token }: any) => {
          login({
            request: { token },
            successCallback(res: LoginSSOResponse) {
              message.success('Logged in succesfully!')
              cookieServices.setCookie(res)
              dispatchAuth({
                type: ActionTypes.SET_USER,
                payload: {
                  email: res?.email,
                  role: res?.role
                }
              })
              fetchPermissions({
                resolve: (res?: any) => {
                  dispatchApp({ type: ActionTypes.SET_PERMISSIONS, payload: res })
                  navigate('/', { replace: true })
                },
                reject: (error?: any) => {
                  message.error(error)
                }
              })
            }
          })
        }}
        onError={() => {
          message.error(
            'Something went wrong when logging in. Please try again or contact the administrator.'
          )
        }}
      />
    </div>
  )
}

export default ButtonSSOGoole

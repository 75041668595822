import { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import AppContext, { AppActionTypes } from '@/context/App'
import AuthContext, { ActionTypes } from '@/modules/auth/context'
import cookieServices from '@/utils/cookieServices'
import { message } from 'antd'

interface PropTypes {
  children?: any,
  whitelistedPaths: Set<string>
}
const ProtectedRoute = ({ children, whitelistedPaths }: PropTypes) => {
  const { authed, user, dispatch }:any = useContext(AuthContext)
  const { message: msg, dispatch: dispatchApp }: any = useContext(AppContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const getPathName = (path:string) => {
    return path.replace('/', '')
  }
  const [currentPath, setCurrentPath] = useState(getPathName(pathname))

  useEffect(() => {
    setCurrentPath(getPathName(pathname))
    if (currentPath !== 'signin' && !whitelistedPaths.has(currentPath)) {
      if (!authed && !user) {
        cookieServices.clearCookie()
        dispatch({ type: ActionTypes.CLEAR_USER })
        return navigate('/signin', { replace: true })
      }
    } else if (currentPath === 'signin' && authed && user) {
      return navigate('/', { replace: true })
    }

    if (msg?.show) {
      switch (msg?.type) {
        case 'error':
          message.error(msg?.message).then(() => dispatchApp({ type: AppActionTypes.CLEAR_MESSAGE }))
          break
        case 'success':
          message.success(msg?.message).then(() => dispatchApp({ type: AppActionTypes.CLEAR_MESSAGE }))
          break
        default:
          message.info(msg?.message).then(() => dispatchApp({ type: AppActionTypes.CLEAR_MESSAGE }))
          break
      }
    }
  }, [authed, user, dispatch, pathname, msg])

  return children
}

export default ProtectedRoute

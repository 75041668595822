import API_URL from '@/services/api'
import { Facilitator } from '../../../../types/entity'
import { CourseFacilitatorType, FacilitatorPayload, FacilitatorsType, FacilitatorType, GetFacilitatorsRequest } from '@/types/contracts/facilitators'
import { BasePaginatedResponse, BaseResponse } from '@/types/contracts/base'
import { APICallFuncParams } from '@/hooks/useAPICall'

interface APICallSpecs<I, O> {
  data: I,
  resolve?: (response: O) => void,
  reject?: (error: any) => void,
  progress?: (inProgress: boolean) => void,

}

export const createFacilitator = async ({
  data,
  resolve = (r) => r,
  reject = (error) => error,
  progress = (inProgress) => inProgress
}: APICallSpecs<Facilitator, Facilitator>) => {
  try {
    progress(true)
    const resp = await API_URL.facilitator.postFacilitator(data) as any
    resolve(resp.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchFacilitators = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<GetFacilitatorsRequest, BasePaginatedResponse<FacilitatorsType[]>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.facilitator.getFacilitators(data?.params)
    resolve(res as BasePaginatedResponse<FacilitatorsType[]>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchFacilitatorDetail = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<GetFacilitatorsRequest, BaseResponse<FacilitatorType>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.facilitator.getFacilitator(data?.params)
    resolve(res as BaseResponse<FacilitatorType>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const createNewUserFacilitator = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<FacilitatorPayload, BaseResponse<any>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.facilitator.postUserFacilitator(data)
    resolve(res as BaseResponse<any>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const updateUserFacilitator = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<FacilitatorPayload, BaseResponse<any>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.facilitator.putUserFacilitator(data, data?.serial)
    resolve(res as BaseResponse<any>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

export const fetchFacilitatorCourses = async ({
  progress,
  reject,
  resolve,
  data
}: APICallFuncParams<GetFacilitatorsRequest, BasePaginatedResponse<CourseFacilitatorType[]>>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await API_URL.facilitator.getFacilitatorCourses(data as Record<string, any>)
    resolve(res as BasePaginatedResponse<CourseFacilitatorType[]>)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}


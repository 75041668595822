import { message } from 'antd'
import _ from 'lodash'
import React from 'react'

export interface APICallFuncParams<RQ, RS> {
  data?: RQ
  resolve: (response: RS) => void
  reject: (error: any) => void
  progress: (isLoading: boolean) => void
}

export interface TriggerParams<RQ, RS> {
  request?: RQ
  errorMessage?: string,
  successCallback?: (response: RS) => void
  errorCallback?: (error: any) => void
  progressCallback?: (loading: boolean) => void
}

export type APICallFunc<RQ, RS> = (params: APICallFuncParams<RQ, RS>) => void

export function useAPICall<RQ, RS>(fn: APICallFunc<RQ, RS>) {
  const [data, setData] = React.useState<RS>()
  const [loading, setLoading] = React.useState<boolean>(false)

  const trigger = ({
    request,
    errorMessage = 'Something went wrong.',
    successCallback = () => null,
    errorCallback,
    progressCallback = () => null
  }: TriggerParams<RQ, RS>) => {
    fn({
      data: request,
      progress: (isLoading: boolean) => {
        progressCallback(isLoading)
        return setLoading(isLoading)
      },
      reject: (error: any) => {
        if (_.isFunction(errorCallback)) {
          errorCallback(error)
          return
        }
        message.error(`${error?.data?.message || errorMessage}`)
      },
      resolve(response: RS) {
        setData(response)
        successCallback(response)
      }
    })
  }

  return { loading, data, trigger }
}

import React from 'react'
import { CloseOutlined } from '@ant-design/icons'

import Styles from './PreviewBanner.module.scss'

interface PropTypes {
  onClose?: any,
  data?: string
}

const PreviewBanner = ({ onClose, data }: PropTypes) => {
  return (
    <div className={Styles['preview-banner']}>
      <div className={Styles['preview-banner-content']}>
        <img src={data} alt="image-banner" />
      </div>
      <div className={Styles['preview-banner-close']} onClick={onClose}>
        <CloseOutlined />
      </div>
    </div>
  )
}

export default PreviewBanner

import { Action } from '@/types/context'
import { QuizDetailType } from '@/types/contracts/quiz'
import { QuizParticipantType } from '@/types/contracts/task'
import { MetaPagination } from '@/types/entity'

export enum QuizActionTypes {
  SET_QUIZ_DETAIL = 'SET_QUIZ_DETAIL',
  SET_QUIZ_DETAIL_LOADING = 'SET_QUIZ_DETAIL_LOADING',
  SET_QUIZ_PARTICIPANT = 'SET_QUIZ_PARTICIPANT',
  SET_QUIZ_PARTICIPANT_LOADING = 'SET_QUIZ_PARTICIPANT_LOADING'
}

export interface QuizState {
  quizDetail?: {
    meta?: MetaPagination,
    data?: QuizDetailType
    isLoading?: boolean
  },
  quizParticipant?: {
    meta?: MetaPagination,
    data?: QuizParticipantType
    isLoading?: boolean
  }
}

const reducer = (state: QuizState, action: Action<QuizActionTypes>): QuizState => {
  switch (action.type) {
    case QuizActionTypes.SET_QUIZ_DETAIL:
      return {
        ...state,
        quizDetail: {
          ...action?.payload,
          isLoading: false
        }
      }
    case QuizActionTypes.SET_QUIZ_DETAIL_LOADING:
      return {
        ...state,
        quizDetail: {
          ...state.quizDetail,
          isLoading: action?.payload ?? false
        }
      }
    case QuizActionTypes.SET_QUIZ_PARTICIPANT:
      return {
        ...state,
        quizParticipant: {
          ...action?.payload,
          isLoading: false
        }
      }
    case QuizActionTypes.SET_QUIZ_PARTICIPANT_LOADING:
      return {
        ...state,
        quizParticipant: {
          ...state.quizParticipant,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

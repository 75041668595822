import { FieldsTypes, LearnerTypes, OccupationTypes, StudyReasonTypes, TopicsTypes } from '@/types/entity'
import { Action, DataPagesType, DataSelectType } from '@/types/context'

export enum ActionTypes {
  SET_LEARNERS = 'SET_LEARNERS',
  SET_LEARNERS_LOADING = 'SET_LEARNERS_LOADING',
  SET_LEARNER = 'SET_LEARNER',
  SET_LEARNER_LOADING = 'SET_LEARNER_LOADING',
  SET_OCCUPATION = 'SET_OCCUPATION',
  SET_OCCUPATION_LOADING = 'SET_OCCUPATION_LOADING',
  SET_STUDY_REASON = 'SET_STUDY_REASON',
  SET_STUDY_REASON_LOADING = 'SET_STUDY_REASON_LOADING',
  SET_TOPIC = 'SET_TOPIC',
  SET_TOPIC_LOADING = 'SET_TOPIC_LOADING',
  SET_FIELDS = 'SET_FIELDS',
  SET_FIELDS_LOADING = 'SET_FIELDS_LOADING'
}

export interface State {
  learners: DataPagesType<LearnerTypes[]>
  learner: DataSelectType<LearnerTypes>
  occupations: DataSelectType<OccupationTypes[]>
  studyReasons: DataSelectType<StudyReasonTypes[]>
  topics: DataSelectType<TopicsTypes[]>
  fields: DataSelectType<FieldsTypes[]>
}

const reducer = (state: State, action: Action<ActionTypes>): State => {
  switch (action.type) {
    case ActionTypes.SET_LEARNERS:
      return {
        ...state,
        learners: {
          data: action?.payload?.data ?? [],
          meta: action?.payload?.meta ?? null,
          isLoading: false
        }
      }
    case ActionTypes.SET_LEARNERS_LOADING:
      return {
        ...state,
        learners: {
          data: state.learners.data,
          meta: state.learners.meta,
          isLoading: action?.payload ?? false
        }
      }
    case ActionTypes.SET_LEARNER:
      return {
        ...state,
        learner: {
          data: action?.payload ?? null,
          isLoading: false
        }
      }
    case ActionTypes.SET_LEARNER_LOADING:
      return {
        ...state,
        learner: {
          data: state.learner.data,
          isLoading: action?.payload ?? false
        }
      }
    case ActionTypes.SET_OCCUPATION:
      return {
        ...state,
        occupations: {
          data: action?.payload ?? [],
          isLoading: false
        }
      }
    case ActionTypes.SET_OCCUPATION_LOADING:
      return {
        ...state,
        occupations: {
          data: state.occupations.data,
          isLoading: action?.payload ?? false
        }
      }
    case ActionTypes.SET_STUDY_REASON:
      return {
        ...state,
        studyReasons: {
          data: action?.payload ?? [],
          isLoading: false
        }
      }
    case ActionTypes.SET_STUDY_REASON_LOADING:
      return {
        ...state,
        studyReasons: {
          data: state.studyReasons.data,
          isLoading: action?.payload ?? false
        }
      }
    case ActionTypes.SET_TOPIC:
      return {
        ...state,
        topics: {
          data: action?.payload ?? [],
          isLoading: false
        }
      }
    case ActionTypes.SET_TOPIC_LOADING:
      return {
        ...state,
        topics: {
          data: state.topics.data,
          isLoading: action?.payload ?? false
        }
      }
    case ActionTypes.SET_FIELDS:
      return {
        ...state,
        fields: {
          data: action?.payload ?? [],
          isLoading: false
        }
      }
    case ActionTypes.SET_FIELDS_LOADING:
      return {
        ...state,
        fields: {
          data: state.fields.data,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

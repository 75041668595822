import React from 'react'
import Styles from './Skeleton.module.scss'

interface PropTypes {
  height?: string
  width?: string
  radius?: string
  type?: string
}

const Skeleton = ({ height = '40px', width = '100%', radius = '4px' }: PropTypes) => {

  return (
    <div style={{ height, width, borderRadius: radius }} className={Styles.skeleton} />
  )
}

export default Skeleton

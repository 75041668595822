import { UploadedFile } from '@/types/entity'
import { Action } from '@/types/context'

export enum UploadActionTypes {
  SET_FILE = 'SET_FILE',
  SET_FILE_LOADING = 'SET_FILE_LOADING'
}

export interface UploadState {
  file?: UploadedFile
  loading: boolean
}

const reducer = (state: UploadState, action: Action<UploadActionTypes>): UploadState => {
  switch (action.type) {
    case UploadActionTypes.SET_FILE:
      return {
        ...state,
        file: action?.payload,
        loading: false
      }
    case UploadActionTypes.SET_FILE_LOADING:
      return {
        ...state,
        loading: action?.payload ?? false
      }
    default:
      return state
  }
}

export default reducer

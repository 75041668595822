import methods from '../methods'

export interface UploadFileProps {
  file: File
}

export interface UploadFileWithTypeProps extends UploadFileProps {
  type?: 'GENERAL' | 'COURSE_MATERIAL'
}

const API_URL = '/api/admin'

const uploadImage = (data: UploadFileProps) => methods.POST_API(
  `${API_URL}/file-upload/image`,
  data,
  {
    'Content-Type': 'multipart/form-data'
  }
)

const uploadFile = (data: UploadFileWithTypeProps) => methods.POST_API(
  `${API_URL}/file-upload/file`,
  data,
  {
    'Content-Type': 'multipart/form-data'
  }
)

export default {
  uploadImage,
  uploadFile
}

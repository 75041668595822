import { AdminNoteEntityType } from '@/types/entity'
import methods from '../methods'

export type CreateNoteProps = {
  pageType: string;
  reason: string;
  isComment: boolean
  identifier?: string
}

export interface GetNotesProps {
  page?: number
  size?: number
  identifier?: string
  pageType: AdminNoteEntityType
}

const API_URL = '/api/admin'

const getNotes = (data: GetNotesProps) => methods.GET_API(`${API_URL}/notes`, data)
const createNote = (data: CreateNoteProps) => methods.POST_API(`${API_URL}/note`, data)

export default {
  getNotes,
  createNote
}

import API_URL from '@/services/api'
import { CategoryType } from '@/types/entity'
import { APICallFuncParams } from '@/hooks/useAPICall'

export const triggerError = (show: boolean, response: any) => {
  // eslint-disable-next-line no-console
  console.error('Error| ', response)
  let message = 'Opps...! Something wrong.'
  if (show && response) {
    const status = response?.status

    switch (true) {
      case status === 401:
        message = 'Opps...! Unauthorized!'
        break
      case status === 403:
        message = 'Opps...! Forbidden!'
        break
      case status === 404:
        message = 'API Endpoint Not Found.!'
        break
      case status === 429:
        message = 'Opps...! Too Many Requests!'
        break
      case status === 500:
        message = `${status}: Internal Server Error !`
        break
      case status === 502:
        message = `${status}: Bad Gateway !`
        break
      default:
        message = `${status}: ${response?.data?.message ?? 'Opps...! Something wrong.'}`
        break
    }
  } else if (show) {
    message = 'Opps...! Something wrong.'
  } else {
    message = ''
  }

  return {
    show,
    message,
    type: 'error'
  }
}

export const fetchCategories = async ({
  data = '',
  resolve,
  reject,
  progress
}: APICallFuncParams<string, CategoryType[]>) => {
  try {
    progress(true)
    const res: any = await API_URL.category.getCategories(data)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

import cookieServices from '@/utils/cookieServices'
import API_URL from '@services/api'
import { APICallFuncParams } from '@/hooks/useAPICall'
import { LoginSSORequest, LoginSSOResponse, ValidationInvitationRequest } from '@/types/contracts/auth'

export const signin = async ({ data, resolve, reject, progress }: any) => {
  try {
    progress(true)
    const res: any = await API_URL.auth.login(data)
    resolve(res.data)
  } catch (error: any) {
    progress(false)
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const loginSSO = async ({ data, resolve, reject, progress }: APICallFuncParams<LoginSSORequest, LoginSSOResponse>) => {
  progress(true)
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res: any = await API_URL.auth.loginSSO(data!)
    resolve(res.data)
  } catch (error: any) {
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  } finally {
    progress(false)
  }
}


export const fetchPermissions = async ({ resolve, reject }: any) => {
  try {
    const token = cookieServices.getToken()
    const headers = {
      Authorization: token
    }
    const res: any = await API_URL.auth.getPermissions(headers)
    localStorage.setItem('permissions', res?.data ?? [])
    resolve(res.data)
  } catch (error: any) {
    const errorResponse = error?.response ?? error
    reject(errorResponse)
  }
}

export const validateInvitation = async ({
  data,
  resolve = (r) => r,
  reject = (error) => error,
  progress = (inProgress) => inProgress
}: APICallFuncParams<ValidationInvitationRequest, any>) => {
  try {
    progress(true)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res: any = await API_URL.auth.validateInvitation(data!)
    resolve(res?.data)
  } catch (error: any) {
    reject(error?.response ?? error)
  } finally {
    progress(false)
  }
}

import { CloseCircleFilled, EditOutlined, FileImageFilled } from '@ant-design/icons'
import { Button, message, UploadProps } from 'antd'
import Dragger from 'antd/lib/upload/Dragger'
import { useContext, useState } from 'react'
import UploadContext, { UploadActionTypes } from '../../../context/Upload'
import { uploadImage } from '../../../context/Upload/action'
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect'

import { AtomsMinimalUploadWrapper } from '../../atoms'

import './styles.scss'
import styles from './UploadImagePreview.module.scss'

interface PropTypes {
  filename?: string
  defaultValue?: string
  value?: string
  width?: number
  height?: number
  fileSize?: number
  onChange?: (url?: string) => void
}

export const dummyFile = new File([], 'name') // TODO: REMOVE

const UploadImagePreview = ({
  filename: defaultFileName = '<filename>',
  defaultValue,
  onChange = (url) => url,
  value,
  width = 1920,
  height = 640,
  fileSize = 500
}: PropTypes) => {
  const [fileName, setFileName] = useState<string | undefined>(defaultFileName)
  const [imageURL, setImageURL] = useState(value ?? defaultValue)

  const { dispatch: dispatchUploadImg, loading: uploading } = useContext(UploadContext)

  function uploadFile(file: File) {
    uploadImage({
      data: { file },
      progress: (payload) => dispatchUploadImg({ type: UploadActionTypes.SET_FILE_LOADING, payload }),
      resolve: (response?) => setImageURL(response?.url),
      reject: (error) => {
        message.error(error)
      }
    })
    setFileName(file.name)
  }

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: 'image/*',
    beforeUpload: () => false,
    onChange({ file }) {
      if (!file) {
        message.error('Something went wrong. Please choose a file again.')
        return
      }

      const filesType = [
        'image/jpeg',
        'image/png'
      ]
      if (!filesType.includes(file.type ?? '')) {
        message.error('You can only upload jpg, .jpeg or .png file!')
        return
      }

      if ((file.size ?? 0) / 1024 > fileSize) {
        message.error(`File must be smaller than ${fileSize} KB!`)
        return
      }

      uploadFile(file as unknown as File)
    },
    disabled: uploading
  }

  useDidUpdateEffect(() => {
    onChange(imageURL)
  }, [imageURL])

  return (
    <>
      {!imageURL &&
        <Dragger {...props} className="course-information__upload-thumbnail">
          <p className="ant-upload-drag-icon">
            <FileImageFilled />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          <p className="ant-upload-hint">
            { `Max file size. ${fileSize} KB in .jpg and .png formats` }
            <br />
            { `Size ${width} x ${height} px` }
          </p>
        </Dragger>
      }
      {imageURL && <div className={styles['image-preview']}>
        <div>
          <img src={imageURL} alt={fileName} />
        </div>
        <div className={styles.uploader}>
          <div className={styles.filename}>
            {fileName} <CloseCircleFilled onClick={() => setImageURL(undefined)} />
          </div>
          <div>
            <AtomsMinimalUploadWrapper {...props} onFileChange={uploadFile}>
              <Button className={styles['edit-button']} type="text" size="small">
                <EditOutlined /> Edit
              </Button>
            </AtomsMinimalUploadWrapper>
          </div>
        </div>
      </div>}
    </>
  )
}

export default UploadImagePreview

import { Action } from '@/types/context'
import { TaskDetailType, TaskSubmissionType } from '@/types/contracts/task'
import { MetaPagination } from '@/types/entity'

export enum TaskActionTypes {
  SET_TASK_DETAIL = 'SET_TASK_DETAIL',
  SET_TASK_DETAIL_LOADING = 'SET_TASK_DETAIL_LOADING',
  SET_SUBMISSIONS = 'SET_SUBMISSIONS',
  SET_SUBMISSIONS_LOADING = 'SET_SUBMISSIONS_LOADING'
}

export interface TaskState {
  taskSubmission?: {
    meta?: MetaPagination,
    data?: TaskSubmissionType
    isLoading?: boolean
  },
  taskDetail?: {
    meta?: MetaPagination,
    data?: TaskDetailType
    isLoading?: boolean
  }
}

const reducer = (state: TaskState, action: Action<TaskActionTypes>): TaskState => {
  switch (action.type) {
    case TaskActionTypes.SET_TASK_DETAIL:
      return {
        ...state,
        taskDetail: {
          ...action?.payload,
          isLoading: false
        }
      }
    case TaskActionTypes.SET_TASK_DETAIL_LOADING:
      return {
        ...state,
        taskDetail: {
          ...state.taskDetail,
          isLoading: action?.payload ?? false
        }
      }
    case TaskActionTypes.SET_SUBMISSIONS:
      return {
        ...state,
        taskSubmission: {
          ...action?.payload,
          isLoading: false
        }
      }
    case TaskActionTypes.SET_SUBMISSIONS_LOADING:
      return {
        ...state,
        taskSubmission: {
          ...state.taskSubmission,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

import { DeleteOutlined, PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, message, Space, Upload, UploadProps } from 'antd'
import { useState } from 'react'

import _ from 'lodash'
import { uploadFile } from '@/context/Upload/action'
import { useDidUpdateEffect } from '@/hooks/useDidUpdateEffect'
import { UploadedFile } from '@/types/entity'

import styles from './UploadFile.module.scss'
import { RcFile } from 'antd/lib/upload'

interface Props {
  onChange?: (files: any[]) => void,
  value?: string[]
  maxFiles?: number
  maxSize?: number
  accept?: string[]
  acceptReadable?: string
}

const UploadMultipleFiles = ({
  onChange = (e) => e,
  value,
  maxFiles,
  maxSize = 500,
  accept = ['image/jpeg', 'image/png', 'application/pdf'],
  acceptReadable = '.jpeg, .png, or .pdf'
}: Props) => {
  const [materialList, setMaterialList] = useState<any[]>(value || [])
  const [uploadMaterialLoading, setUploadMaterialLoading] = useState<boolean>(false)

  useDidUpdateEffect(() => {
    onChange(materialList)
  }, [materialList])

  const removeMaterial = (index: number) => {
    const newMaterial = [...materialList]
    newMaterial.splice(index, 1)
    setMaterialList([...newMaterial])
  }

  const uploadMaterial = (file: File, callback: any) => {
    uploadFile({
      data: { file, type: 'COURSE_MATERIAL' },
      reject(error: any) {
        message.error(`Something went wrong when uploading material, please contact the administrator. ${error}`)
      },
      resolve(response?: UploadedFile) {
        callback(response)
        message.success('File is uploaded successfully!')
      },
      progress(status?: boolean) {
        setUploadMaterialLoading(status ?? false)
      }
    })
  }

  const isFileValid = (file: RcFile | undefined) => {
    if (!file) {
      message.error('Something went wrong, please choose a file again.')
      return false
    }

    if (!accept.includes(file.type)) {
      message.error(`You can only upload ${acceptReadable} file!`)
      return false
    }

    if (file.size / 1024 > maxSize) {
      message.error('File must be smaller than 500KB!')
      return false
    }

    return true
  }

  const propsMaterial: UploadProps = {
    name: 'file',
    accept: accept.join(','),
    showUploadList: false,
    beforeUpload: (file: RcFile) => {
      return isFileValid(file) ? false : Upload.LIST_IGNORE
    },
    onChange({ file }) {
      const f = file as RcFile
      if (!isFileValid(f)) {
        return
      }

      uploadMaterial(f, (result: UploadedFile) => {
        setMaterialList([...materialList, result])
      })
    },
    disabled: uploadMaterialLoading
  }

  const maxFilesReached = maxFiles ? materialList.length >= maxFiles : false
  const maxFileSize = maxSize / 1000 < 1 ? `${maxSize} KB` : `${maxSize / 1000} MB`

  return (
    <>
      <div className={`${styles['placeholder-upload']} mb-2`}>
        Max file size. {maxFileSize} in {acceptReadable} formats
      </div>
      {!maxFilesReached && <Upload className={styles['content-uploader']} {...propsMaterial}>
        <Button size="large" style={{ borderColor: '#2A9D8C' }} icon={<UploadOutlined />}>Upload File</Button>
      </Upload>}

      { !_.isEmpty(materialList) && <ul className={`${styles['files-upload']} mt-2`}>
        { materialList.map((material: UploadedFile, index: number) =>
          <li key={index}>
            <Space>
              <PaperClipOutlined />
              <div className={styles['files-upload__name']}>{material.name}</div>
            </Space>
            <Button style={{ color: '#D60B00' }} type="link" icon={<DeleteOutlined />} onClick={() => removeMaterial(index)} />
          </li>
        ) }
      </ul>}
    </>
  )
}

export default UploadMultipleFiles

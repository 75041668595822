import React, { createContext, useReducer } from 'react'
import { Action, ContextType } from '@/types/context'
import reducer, { State, ActionTypes } from './reducer'

interface PropTypes {
  children: React.ReactNode
}

const initialState: State = {
  ratings: {
    data: [],
    meta: {
      page: 0,
      pageSize: 0,
      total: 0,
      totalPage: 0
    },
    isLoading: false
  }
}


const RatingContext = createContext<ContextType<State, Action<ActionTypes>>>({
  ...initialState,
  dispatch: () => null
})

export const RatingProvider = ({ children }: PropTypes) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <RatingContext.Provider value={{ ...state, dispatch }}>
    {children}
  </RatingContext.Provider>

}

export default RatingContext
export { ActionTypes }

import { UpdateRolesRequest } from '@/types/contracts/permission'
import methods from '../methods'

const API_URL = '/api/admin/rbac'

const getRoles = () => methods.GET_API(`${API_URL}/roles`)
const updateRoles = (payload: UpdateRolesRequest) => methods.PUT_API(`${API_URL}/roles`, payload)

export default {
  getRoles,
  updateRoles
}

import { Action, DataSelectType } from '@/types/context'
import { FacilitatorType } from '@/types/contracts/facilitators'

export enum ActionTypes {
  SET_FACILITATOR = 'SET_FACILITATOR',
  SET_FACILITATOR_LOADING = 'SET_FACILITATOR_LOADING'
}

export interface State {
  facilitator: DataSelectType<FacilitatorType>
}

const reducer = (state: State, action: Action<ActionTypes>): State => {
  switch (action.type) {
    case ActionTypes.SET_FACILITATOR:
      return {
        ...state,
        facilitator: {
          data: action?.payload?.data ?? null,
          isLoading: false
        }
      }
    case ActionTypes.SET_FACILITATOR_LOADING:
      return {
        ...state,
        facilitator: {
          data: state.facilitator.data,
          isLoading: action?.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

import { CombineComponents } from '@/utils/combineComponents'
import { AppProvider } from './App'
import { AuthProvider } from '@/modules/auth/context'
import { NotesProvider } from './Notes'
import { UploadProvider } from './Upload'
import { BannerProvider } from '@/modules/site-menu/Banner/context'
import { UserProvider } from '@/modules/users/User/context'
import { CourseProvider } from '@/modules/course/CourseList/context'
import { RatingProvider } from '@/modules/users/RatingReview/context'
import { TransactionProvider } from '@/modules/transaction/context'
import { FacilitatorProvider } from '@/modules/users/Facilitator/context'
import { TaskProvider } from '@/modules/course/Task/context'
import { QuizProvider } from '@/modules/course/Quiz/context'


const providers = [
  AppProvider,
  AuthProvider,
  NotesProvider,
  BannerProvider,
  UploadProvider,
  UserProvider,
  CourseProvider,
  RatingProvider,
  TransactionProvider,
  FacilitatorProvider,
  TaskProvider,
  QuizProvider
]

export const GlobalContextProvider = CombineComponents(...providers)

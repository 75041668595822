import { CourseType, MetaPagination, Tag } from '@/types/entity'
import { Action } from '@/types/context'

export enum CourseActionTypes {
  SET_COURSE = 'SET_COURSE',
  SET_COURSE_LOADING = 'SET_COURSE_LOADING',

  SET_COURSES = 'SET_COURSES',
  SET_COURSES_LOADING = 'SET_COURSES_LOADING',

  SET_CONTENT_VIDEO_UPLOAD = 'SET_CONTENT_VIDEO_UPLOAD',

  SET_TAGS = 'SET_TAGS',
  SET_TAGS_LOADING = 'SET_TAGS_LOADING'
}

interface CourseListType {
  meta?: MetaPagination,
  data?: CourseType[]
  isLoading: boolean
}

interface ContentsType {
  attachmentSerial?: string
  uploadURL?: string
}

export interface CourseState {
  course: { data?: CourseType, isLoading: boolean }
  tags: { data?: Tag[], isLoading: boolean }
  courses: CourseListType
  contentUrl?: ContentsType
}

const reducer = (state: CourseState, action: Action<CourseActionTypes>): CourseState => {
  switch (action.type) {
    case CourseActionTypes.SET_COURSE:
      return {
        ...state,
        course: {
          data: action.payload,
          isLoading: false
        }
      }
    case CourseActionTypes.SET_COURSE_LOADING:
      return {
        ...state,
        course: {
          ...state.course,
          isLoading: action?.payload ?? false
        }
      }
    case CourseActionTypes.SET_COURSES:
      return {
        ...state,
        courses: {
          ...action?.payload,
          isLoading: false
        }
      }
    case CourseActionTypes.SET_COURSES_LOADING:
      return {
        ...state,
        courses: {
          ...state?.courses,
          isLoading: action?.payload ?? false
        }
      }
    case CourseActionTypes.SET_CONTENT_VIDEO_UPLOAD:
      return {
        ...state,
        contentUrl: action?.payload
      }
    case CourseActionTypes.SET_TAGS:
      return {
        ...state,
        tags: {
          data: action.payload,
          isLoading: false
        }
      }
    case CourseActionTypes.SET_TAGS_LOADING:
      return {
        ...state,
        tags: {
          ...state.tags,
          isLoading: action.payload ?? false
        }
      }
    default:
      return state
  }
}

export default reducer

import methods from '../methods'
import { GetLearnersRequest, GetUserCoursesRequest, LearnerPayload } from '@/types/contracts/user'
import { DeleteLearner, LearnerDataType } from '@/types/entity'

const API_URL = '/api/admin/users'

const getUsers = (params?: string) => methods.GET_API(`${API_URL}?${params}`)
const getUserCourses = ({ userSerial, ...data }: GetUserCoursesRequest) => methods.GET_API(`${API_URL}/learner/${userSerial}/courses`, data)
const getUserCoursesTotalDuration = (userSerial: string) => methods.GET_API(`${API_URL}/learner/${userSerial}/courses/duration`)
const getLearners = (params?: string) => methods.GET_API(`${API_URL}/learners?${params}`)
const getLearnersList = (data: GetLearnersRequest) => methods.GET_API(`${API_URL}/learners`, data)
const getLearner = (serial?: string) => methods.GET_API(`${API_URL}/learner/${serial}`)
const editLearner = (serial: string, data: LearnerDataType) => methods.PUT_API(`${API_URL}/learner/${serial}`, data)
const deleteLearner = (data: DeleteLearner) => methods.POST_API(`${API_URL}/learner/delete`, data)
const addLearner = (data?: LearnerPayload) => methods.POST_API(`${API_URL}/learner`, data)

export default {
  getUsers,
  getUserCourses,
  getUserCoursesTotalDuration,
  getLearners,
  getLearnersList,
  getLearner,
  editLearner,
  deleteLearner,
  addLearner
}

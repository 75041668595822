import { FacilitatorPayload } from '@/types/contracts/facilitators'
import { Facilitator } from '../../types/entity'
import methods from '../methods'

const API_URL = '/api'

const postFacilitator = (data: Facilitator) => methods.POST_API(`${API_URL}/admin/facilitators`, data)
const getFacilitators = (params?: string) => methods.GET_API(`${API_URL}/admin/facilitators?${params}`)
const getFacilitator = (serial?: string | number) => methods.GET_API(`${API_URL}/admin/facilitator/${serial}`)
const postUserFacilitator = (data?: FacilitatorPayload) => methods.POST_API(`${API_URL}/admin/user-facilitator`, data)
const putUserFacilitator = (data?: FacilitatorPayload, serial?: string) => methods.PUT_API(`${API_URL}/admin/facilitator/${serial}`, data)
const getFacilitatorCourses = ({ serial, params }: Record<string, any>) => methods.GET_API(`${API_URL}/admin/facilitator/${serial}/course?${params}`)

export default {
  postFacilitator,
  getFacilitators,
  getFacilitator,
  postUserFacilitator,
  putUserFacilitator,
  getFacilitatorCourses
}

import { CreateTagRequest } from '../../types/contracts/tag'
import methods from '../methods'

const API_URL = '/api'

const getTags = (query?: string) => methods.GET_API(`${API_URL}/tags?${query}`)
const postTag = (data?: CreateTagRequest) => methods.POST_API(`${API_URL}/admin/tags`, data)

export default {
  getTags,
  postTag
}
